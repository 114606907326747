import React from "react";

const DistanceConverter = ({ distance }) => {
  // Convert the string distance to a float
  const distanceInKm = parseFloat(distance);
  console.log('distance', distanceInKm)

  // Handle invalid or missing distance
  if (isNaN(distanceInKm)) {
    return <div>Invalid distance</div>;
  }

  // Convert to meters and miles
  const distanceInMeters = distanceInKm * 1000;
  const distanceInMiles = distanceInKm * 0.621371;

  // Choose the most appropriate unit for display
  let displayDistance = "";
  if (distanceInKm < 1) {
    displayDistance = `${distanceInMeters.toFixed(0)} meters`;
  } else if (distanceInKm >= 1 && distanceInKm < 1000) {
    displayDistance = `${distanceInKm.toFixed(2)} kilometers`;
  } else {
    displayDistance = `${distanceInMiles.toFixed(2)} miles`;
  }

  return displayDistance;
};

export default DistanceConverter;
