import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Creators as loginCreators } from "./view/auth/store";
import { setAccessTokenToHeader } from "./services/axios/helper";
import toast, { Toaster } from 'react-hot-toast';
import GoogleTagManager from "./Common/GoogleAdd/GoogleTagManager";
import { getUserDataFromLocal } from "./shared/utility/helper";
import { MainRoutes } from "./routes/Routes";


function App() {
  const { userData } = useSelector((state) => state.loginData);
  const { name, email, id } = userData || {};
  const dispatch = useDispatch();

  useEffect(() => {
    const userData = getUserDataFromLocal();
    if (userData?.token) {
      dispatch(
        loginCreators.setUserDetails({
          token: userData.token,
          userData: userData.userData,
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (name || email) {
      setAccessTokenToHeader(name || id || email);
    }
  }, [name, email, id]);

  return (
    <>
      
      {/* <GoogleTagManager /> */}
      
      <Toaster />
      <MainRoutes />
    </>
  );
}

export default App;