// Function to format the phone number
const formatPhoneNumber = (number) => {
  // Ensure `number` is a string before calling `replace`
  const cleaned = (number || '').replace(/\D/g, ''); // Remove non-digit characters

  // Check if it's a valid 10-digit number
  if (cleaned.length !== 10) {
    return 'Invalid phone number'; // If the length isn't 10 digits, return an error message
  }

  // Format the phone number
  return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
};


  export default formatPhoneNumber