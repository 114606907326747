import { createReducer } from "reduxsauce";
import { DocumentTypes } from "./action";


const INITIAL_STATE = {
    documentLoading: false,
    documentList: [],
    documentCount: '',
    error: ''
}


const getDocumentListStart = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        documentLoading: true
    }
}

const getDocumentListSuccess = (state = INITIAL_STATE, action) => {
    const { data, message } = action.payload
    
    return {
        ...state,
        documentLoading: false,
        documentList: data,
        documentCount: data.length 
    }
}

const getDocumentListFailure = (state = INITIAL_STATE, action) => {
    
    return { 
        ...state,
        documentLoading: false
     }
}


const HANDLERS = {
    [DocumentTypes.GET_DOCUMENT_LIST_START]: getDocumentListStart,
    [DocumentTypes.GET_DOCUMENT_LIST_SUCCESS]: getDocumentListSuccess,
    [DocumentTypes.GET_DOCUMENT_LIST_FAILURE]: getDocumentListFailure
}

export const documentReducer = createReducer(INITIAL_STATE, HANDLERS)