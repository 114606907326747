import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useNavigate, Link } from "react-router-dom";
import { rootConfig } from "../../../../services/axios/endPoints";

const useAuth = () => {
  const user = localStorage.getItem("user");
  if (user) {
    return true;
  } else {
    return false;
  }
};

const Sidebar = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const dispatch = useDispatch();

  const { profileData } = useSelector(
    (state) => state.profileData
  );



  const { loginmsg, userData } = useSelector((state) => state.loginData);

  return (
    <>
      <aside className="main-sidebar" >

        <section className="sidebar">

          <div className="user-panel">
            <div className="pull-left image">
              <img src={rootConfig.image_url + profileData?.profile} className="img-circle" alt="User Image" />
            </div>
            <div className="pull-left info">
              <p>{_.startCase(_.toLower(profileData?.name))}</p>
              {/* <Link to="#"><i className="fa fa-circle text-success"></i> Online</Link> */}
            </div>
          </div>

           {/* <form action="#" method="get" className="sidebar-form">
              <div className="input-group">
                <input type="text" name="q" className="form-control" placeholder="Search..." />
                <span className="input-group-btn">
                      <button type="submit" name="search" id="search-btn" className="btn btn-flat"><i className="fa fa-search"></i>
                      </button>
                    </span>
              </div>
            </form> */}

          <ul className="sidebar-menu" data-widget="tree"  style={{ height: "550px", overflowY: "auto" }}>
            <li className="header">MAIN NAVIGATION</li>
            <li className="active treeview">
              <Link to="/dashboard">
                <i className="fa fa-dashboard"></i> <span>Dashboard</span>
              </Link>
            </li>
            <li className="treeview menu-open">
              <Link to="/dashboard/booking">
                <i className="fa fa-files-o"></i>
                <span>Booking</span>
                {/* <span className="pull-right-container">
                  <span className="label label-primary pull-right">  </span>
                </span> */}
              </Link>
              <ul className="treeview-menu active">
                <li><Link to="/dashboard/booking"><i className="fa fa-circle-o"></i> Booking</Link></li>
              </ul>
            </li>
            <li className="treeview">
              <Link to="/dashboard/passenger">
                <i className="fa fa-files-o"></i>
                <span>Passenger</span>
                {/* <span className="pull-right-container">
                  <span className="label label-primary pull-right">  </span>
                </span> */}
              </Link>
              <ul className="treeview-menu">
                <li><Link to="/dashboard/passenger"><i className="fa fa-circle-o"></i> Passenger</Link></li>
              </ul>
            </li>
            <li className="treeview">
              <Link to="/dashboard/driver">
                <i className="fa fa-files-o"></i>
                <span>Driver</span>
                
              </Link>
              <ul className="treeview-menu">
                <li><Link to="/dashboard/driver"><i className="fa fa-circle-o"></i> Driver</Link></li>
              </ul>
            </li>
            <li className="treeview">
              <Link to="/dashboard/owner">
                <i className="fa fa-files-o"></i>
                <span>Owner</span>
              </Link>
              <ul className="treeview-menu">
                <li><Link to="/dashboard/owner"><i className="fa fa-circle-o"></i> Owner</Link></li>
              </ul>
            </li>

            <li className="treeview">
              <Link to="/dashboard/documents">
                <i className="fa fa-files-o"></i>
                <span>Documents</span>
              </Link>
              <ul className="treeview-menu">
                <li><Link to="/dashboard/documents"><i className="fa fa-circle-o"></i> Documents</Link></li>
              </ul>
            </li>
            <li className="treeview">
              <Link to="/dashboard/setting">
                <i className="fa fa-files-o"></i>
                <span>Setting</span>
              </Link>
              <ul className="treeview-menu">
                <li><Link to="/dashboard/setting"><i className="fa fa-circle-o"></i> Setting</Link></li>
              </ul>
            </li>
            <li className="treeview">
              <Link to="/dashboard/support/ticket/list">
                <i className="fa fa-files-o"></i>
                <span>Support Ticket</span>
                <span className="pull-right-container">
                  <span className="label label-danger pull-right"> 10 </span>
                </span>
              </Link>
              <ul className="treeview-menu">
                <li><Link to="/dashboard/support/ticket/list"><i className="fa fa-circle-o"></i> Support Ticket</Link></li>
              </ul>
            </li>
            <li className="treeview">
              <Link to="/dashboard/support/reason/list">
                <i className="fa fa-files-o"></i>
                <span>Support Reason List</span>
              </Link>
              <ul className="treeview-menu">
                <li><Link to="/dashboard/support/reason/list"><i className="fa fa-circle-o"></i> Support Reason List</Link></li>
              </ul>
            </li>
            <li className="treeview">
              <Link to="/dashboard/cancel/reason/list">
                <i className="fa fa-files-o"></i>
                <span>Cancel Reason List</span>
              </Link>
              <ul className="treeview-menu">
                <li><Link to="/dashboard/cancel/reason/list"><i className="fa fa-circle-o"></i> Cancel Reason List</Link></li>
              </ul>
            </li>
            <li className="treeview">
              <Link to="/dashboard/vehicle/list">
                <i className="fa fa-files-o"></i>
                <span> Add Vehicles</span>
              </Link>
              <ul className="treeview-menu">
                <li><Link to="/dashboard/vehicle/list"><i className="fa fa-circle-o"></i> Vehicles</Link></li>
              </ul>
            </li>
            <li className="treeview">
              <Link to="/dashboard/vehicle/model/list">
                <i className="fa fa-files-o"></i>
                <span>Add Model</span>
              </Link>
              <ul className="treeview-menu">
                <li><Link to="/dashboard/vehicle/model/list"><i className="fa fa-circle-o"></i> Add Model</Link></li>
              </ul>
            </li>

            <li className="treeview">
              <Link to="/dashboard/vehicle/type/list">
                <i className="fa fa-files-o"></i>
                <span>Add Vehicle Type</span>
              </Link>
              <ul className="treeview-menu">
                <li><Link to="/dashboard/vehicle/type/list"><i className="fa fa-circle-o"></i> Add Vehicle Type</Link></li>
              </ul>
            </li>
            
          </ul>
        </section>

      </aside>
    </>
  )
}

export default Sidebar