/* eslint-disable max-len */
export const apiRootConfig = {
    apiRoot: "https://travelteachergroup.com:8081/",
    base_url: "https://travelteachergroup.com:8081/",
    image_url: "https://travelteachergroup.com:8081/uploads/",

    // REverce GPS link
    google_map_reverse_link: `https://nominatim.openstreetmap.org/reverse?format=json`,

    // firebase

    firebase_api_key: "AIzaSyC9S5wCQqw7skqeCG01ct465ogSfEf20W8",
    firebase_auth_domain: "serviceora-6117f.firebaseapp.com",
    firebase_project_id: "serviceora-6117f",
    firebase_storage_bucket: "serviceora-6117f.appspot.com",
    firebase_message_sender_id: "41898727243",
    firebase_app_id: "1:41898727243:web:30668acc8692d281b8face",
    firebase_measurement_id: "G-VFTKWGKPCC",
    firebase_vapid_key: "BGLEsTnwlvbwjEpy1niRAY5R6QRXw0OMJPMO3q-Ei5JkKZE15ls9FsPe4PD_xHJXp7M55hi0rkz6evhpo0iPwvg"
    //O8e945XdMmolagfXHIMnCdS-19xvZJ_iTseKTVit92w
  };


  {/* <PlacesPicker
        gMapsKey='AIzaSyDuPFWt0J9KYDkbkbPQH9gzi7fWbhfoFpM'
        placeId={value}
        onChange={setValue}
      /> */}
  
  