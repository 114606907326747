import React from "react";
import Switch from "react-custom-checkbox";

const SwitchComponent = ({ checked, onChange }) => {
  const trackStyle = {
    display: "inline-block",
    width: "50px",
    height: "25px",
    borderRadius: "15px",
    backgroundColor: checked ? "#44aa44" : "#f34334",
    position: "relative",
    cursor: "pointer",
    transition: "background-color 0.3s",
  };

  const indicatorStyle = {
    width: "20px",
    height: "20px",
    backgroundColor: "#ffffff",
    borderRadius: "50%",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    left: checked ? "calc(100% - 22px)" : "3px",
    transition: "left 0.3s",
  };

  return (
    <div onClick={onChange} style={trackStyle}>
      <div style={indicatorStyle}></div>
    </div>
  );
};

export default SwitchComponent;
