import { put } from 'redux-saga/effects';
import { DocumentCreators } from './action';
import { WebService } from '../../../../../services/axios/webServices';
import { apiEndPoints } from '../../../../../services/axios/endPoints';
import { getUserDataFromLocal } from '../../../../../shared/utility/helper';

let userData = getUserDataFromLocal();

export function* getDocumentListSaga({ payload }) {
    
    
  try {
    const params = payload;
    yield put(DocumentCreators.getDocumentListStart());
    // const response = yield WebService.post(apiEndPoints.DocumentList, {
    //     headers: { token: userData.token },
    // });
    const response = yield WebService.post(
        apiEndPoints.documentList,
        JSON.stringify(params),
        {
          headers: {
            token: `${userData.token}`,
          },
        }
      );

    if (response.status === 200) {
      const { data, msg } = response.data;
      yield put(DocumentCreators.getDocumentListSuccess({ data, message: msg }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message || error.response.data.Message
          };

          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };

          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };

          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
      }
    } else {
      // for custom error
      payload = {
        error: 'No connection try again Later.'
        // error: 'custom error'
      };
    }
    yield put(DocumentCreators.getDocumentListFailure(payload));
  }
}
