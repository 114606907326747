import { createReducer } from "reduxsauce";
import { BookingTypes } from "./action";


const INITIAL_STATE = {
    bookingLoading: false,
    bookingList: [],
    bookingCount: '',
    error: ''
}


const getBookingListStart = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        bookingLoading: true
    }
}

const getBookingListSuccess = (state = INITIAL_STATE, action) => {
    const { data, message } = action.payload
    
    return {
        ...state,
        bookingLoading: false,
        bookingList: data,
        bookingCount: data.length 
    }
}

const getBookingListFailure = (state = INITIAL_STATE, action) => {
    
    return { 
        ...state,
        bookingLoading: false
     }
}


const HANDLERS = {
    [BookingTypes.GET_BOOKING_LIST_START]: getBookingListStart,
    [BookingTypes.GET_BOOKING_LIST_SUCCESS]: getBookingListSuccess,
    [BookingTypes.GET_BOOKING_LIST_FAILURE]: getBookingListFailure
}

export const bookingReducer = createReducer(INITIAL_STATE, HANDLERS)