import { createReducer } from "reduxsauce";
import { Types } from "./action";

const INITIAL_STATE = {
  error: "",
  loading: false,
  token: null,
  authenticated: false,
  userData: {},
  loginError: "",
  loginmsg: "",
  authErr: [],
};

const onLoginStart = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const onLoginSuccess = (state = INITIAL_STATE, action) => {
  
  return {
    ...state,
    loading: false,
    authenticated: action.payload.authenticated,
    loginmsg: action.payload.msg,
    error: "",
  };
};

const onLoginFail = (state = INITIAL_STATE, action) => {
  
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error: error,
    // authErr: [errorArr],
  };
};

const setUserDetails = (state = INITIAL_STATE, action) => {
  
  return {
    ...state,
    token: action.payload.token,
    userData: {
      ...state.userData,
      ...action.payload.userData,
      email: action.payload.userData.mail,
    },
    authenticated: true,
  };
};

const onLogoutSuccess = (state = INITIAL_STATE) => ({
  ...state,
  authenticated: false,
});
const HANDLERS = {
  [Types.ON_LOGIN_START]: onLoginStart,
  [Types.ON_LOGIN_SUCCESS]: onLoginSuccess,
  [Types.ON_LOGIN_FAIL]: onLoginFail,
  [Types.SET_USER_DETAILS]: setUserDetails,
  [Types.ON_LOGOUT_SUCCESS]: onLogoutSuccess
};

export const loginReducer = createReducer(INITIAL_STATE, HANDLERS);
