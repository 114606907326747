import React, { useState, useRef, useCallback, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../../services/axios/auth.servies";
import { useDispatch, useSelector } from "react-redux";
import { Creators as AuthCreator, Creators } from "./store/index";
import { debounce } from "lodash";
// import { WebService } from "../../services/axios/webServices";
import { apiEndPoints, rootConfig } from "../../services/axios/endPoints";
// import { setAccessTokenToHeader } from "../../services/axios/helper";

import validator from 'validator'
import Button from 'react-bootstrap-button-loader';



import {
  getUserDataFromLocal,
  setUserDataToLocal,
} from "../../shared/utility/helper";
import toast, { Toaster } from "react-hot-toast";
import { ErrorP } from "../../Common/Modal/Modal";
import { isEmail } from "../../shared/utility/regex";
import Loader from "../../Common/Loader/Loader";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { sagaCatchBlockHandling } from "../../store/utility/sagaErrorHandling";
import { WebService } from "../../services/axios/webServices";






export const DeleteAccount = () => {
  const userData = getUserDataFromLocal();
  const { error, loading, authenticated, loginmsg } = useSelector(
    (state) => state.loginData
  );
  const [Loading, setLoading] = useState(false)
  const form = useRef();
  const checkBtn = useRef();
  const [loginInput, setLoginInput] = useState({ email: "", password: ""});
  const [message, setMessage] = useState("");
  const [isEmpty, setIsEmpty] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorList, seterrorList] = useState([])
  const [passwordType, setPasswordType] = useState("password");
  const [errorPhone, setErrorPhone] = useState(false)
  const [isData, setIsData] = useState(false)


  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setLoginInput({ ...loginInput, [name]: value });
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }

    
    const handleSubmitDelete = async () => {
        setLoading(true);
    
        try {
            const response = await WebService.post(
                apiEndPoints.DeleteAccount,
                JSON.stringify(loginInput)
            );

            console.log('response', response)
    
            if (response.status === 200) {
                // console.log('data', response.status)
                toast.error(response.data.msg, { position: "top-center" });
                setIsData(response.data.msg);
            } else {
                toast.error("Failed to delete account. Please try again.", { position: "top-center" });
            }
        } catch (e) {
            const error = sagaCatchBlockHandling(e);
            toast.error(error?.message || "Something went wrong!", { position: "top-center" });
        } finally {
            setLoading(false);
        }
    };
    

//   const debounceOnLogin = useCallback(debounce(handleLogin, 400), [
//     handleLogin,
//   ]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loginInput.email !== "" && loginInput.password !== "") {
      handleSubmitDelete()
      if (error !== "" && loginmsg === "") {
        toast.error(error, { position: "top-center" });
      }
    } else {
      setIsEmpty(true);
    }
  };

  useEffect(() => {
    if (authenticated) {
      navigate("/dashboard");
      navigate(0)
    }
  }, [authenticated]);

  useEffect(() => {
    dispatch(Creators.onLoginSuccess({ msg: "", authenticated: false }));
  }, [loginInput]);

  

  useEffect(() => {
    
  }, []);

  useEffect(() => {
    if (error) {
      console.log(error)
      toast?.error(error, { position: "top-center" });
    }
  }, [error]);

  const OpenHomePage = () => {
    navigate('/')
  }

  // LocalNotifications.schedule({
  //   notifications: [
  //     {
  //       title: "On sale",
  //       body: "Widgets are 10% off. Act fast!",
  //       id: 1,
  //       schedule: { at: new Date(Date.now()) },
  //       sound: null,
  //       attachments: null,
  //       actionTypeId: "",
  //       extra: null
  //     }
  //   ]
  // });

  const validatePhoneNumber = (number) => {
    const isValidPhoneNumber = validator.isMobilePhone(number)
    return (isValidPhoneNumber)
  }

  // const handleOtpLogin = (loginInput) => {
  //     const phone = loginInput?.email;
  //     if( !(phone.match('[0-9]{10}')) ){
  //         console.log('Please provide valid phone number');
  //         setErrorPhone({phone: 'Please provide valid phone number.'})
  //     }else{
  //       setErrorPhone({phone:''})
  //       console.log('good to go there');
  //     }
  //     console.log('errorPhone', errorPhone)
  // }

  return (
    <>
      <Toaster />
      <div className="login-box" style={{background:'#d2d6de'}}>
        
        <div className="login-logo">
        <img src={rootConfig.image_url + 'travel-teacher.jpg'} className="img-circle" alt="User Image" height="50px" /><br/>
          <a href="/login">Travel Teacher</a>
        </div>
        <div className="login-box-body">
          <p className="login-box-msg">Request to Delete My Account</p>
          <p style={{color:'red'}}>{isData ? isData : ''}</p>

          <form onSubmit={handleSubmit} method="post">
            <div className="form-group has-feedback">
              <input type="text"
                      name="email"
                      value={loginInput.email}
                      className="form-control"
                      onChange={handleOnChange}
                      placeholder="Your Email address" />
              <span className="glyphicon glyphicon-envelope form-control-feedback"></span>
              {loginInput?.email === "" && isEmpty ? (
                      <ErrorP>The email field is required!</ErrorP>
                    ) : !isEmail(loginInput?.email) &&
                      loginInput?.email !== "" &&
                      isEmpty ? (
                      <ErrorP>The email must be a valid email address!</ErrorP>
                    ) : null}
                    {
                      errorPhone?.phone === "" && isEmpty ?
                        null
                        : <ErrorP>{errorPhone?.phone}</ErrorP>
                    }
            </div>
            <div className="form-group has-feedback">
              <input type={passwordType}
                        name="password"
                        value={loginInput.password}
                        className="form-control pass-input"
                        onChange={handleOnChange}
                        placeholder="Password"
                        autoComplete="off" />
              <span className="glyphicon glyphicon-lock form-control-feedback"></span>
              {loginInput?.password === "" && isEmpty ? (
                      <ErrorP>The password field is required!</ErrorP>
                    ) : null}
            </div>
            <div className="row">
              <div className="col-xs-7">
                <div className="checkbox icheck">
                  {/* <label>
                    <input type="checkbox" /> Remember Me
                  </label> */}
                </div>
              </div>
              <div className="col-xs-5">
                <Button type="submit" className="btn btn-danger btn-block btn-flat" loading={loading ? true : false} >Delete Account</Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
