import { createReducer } from "reduxsauce";
import { VehicleModelTypes } from "./action";


const INITIAL_STATE = {
    vehicleModelLoading: false,
    vehicleModelList: [],
    vehicleModelCount: '',
    error: ''
}


const getVehicleModelListStart = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        vehicleModelLoading: true
    }
}

const getVehicleModelListSuccess = (state = INITIAL_STATE, action) => {
    const { data, message } = action.payload
    
    return {
        ...state,
        vehicleModelLoading: false,
        vehicleModelList: data,
        vehicleModelCount: data.length 
    }
}

const getVehicleModelListFailure = (state = INITIAL_STATE, action) => {
    
    return { 
        ...state,
        vehicleModelLoading: false
     }
}


const HANDLERS = {
    [VehicleModelTypes.GET_VEHICLE_MODEL_LIST_START]: getVehicleModelListStart,
    [VehicleModelTypes.GET_VEHICLE_MODEL_LIST_SUCCESS]: getVehicleModelListSuccess,
    [VehicleModelTypes.GET_VEHICLE_MODEL_LIST_FAILURE]: getVehicleModelListFailure
}

export const vehicleModelReducer = createReducer(INITIAL_STATE, HANDLERS)