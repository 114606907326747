import { createReducer } from "reduxsauce";
import { SupportReasonTypes } from "./action";


const INITIAL_STATE = {
    supportReasonLoading: false,
    supportReasonList: [],
    supportReasonCount: '',
    error: ''
}


const getSupportReasonListStart = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        supportReasonLoading: true
    }
}

const getSupportReasonListSuccess = (state = INITIAL_STATE, action) => {
    const { data, message } = action.payload
    
    return {
        ...state,
        supportReasonLoading: false,
        supportReasonList: data,
        supportReasonCount: data.length 
    }
}

const getSupportReasonListFailure = (state = INITIAL_STATE, action) => {
    
    return { 
        ...state,
        supportReasonLoading: false
     }
}


const HANDLERS = {
    [SupportReasonTypes.GET_SUPPORT_REASON_LIST_START]: getSupportReasonListStart,
    [SupportReasonTypes.GET_SUPPORT_REASON_LIST_SUCCESS]: getSupportReasonListSuccess,
    [SupportReasonTypes.GET_SUPPORT_REASON_LIST_FAILURE]: getSupportReasonListFailure
}

export const supportReasonReducer = createReducer(INITIAL_STATE, HANDLERS)