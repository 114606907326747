import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { SupportReasonCreators } from "./store";
import { getUserDataFromLocal } from "../../../../shared/utility/helper";
import Sidebar from "../Section/Sidebar";
import Footer from "../Section/Footer";
import toast, { Toaster } from "react-hot-toast";
import _ from "lodash";
import moment from "moment";
import Loader from "../../../../Common/Loader/Loader";
import SwitchComponent from "../../../../Common/SwitchComponent/SwitchComponent";
import formatPhoneNumber from "../../../../Common/PhoneNumber/PhoneNumber";
import { apiEndPoints, rootConfig } from "../../../../services/axios/endPoints";
import { WebService } from "../../../../services/axios/webServices";
import { getBase64 } from "../../../../shared/utility/getBase64";
import Select from "react-select";
import Modal from "../../../../Common/Modal/Modal"

export const SupportReason = () => {
  const userData = getUserDataFromLocal();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isMediumModalOpen, setIsMediumModalOpen] = useState(false);
  const closeMediumModal = () => setIsMediumModalOpen(false);
  const [isInputData, setIsInputData] = useState({})


  const { supportReasonLoading, supportReasonList } = useSelector((state) => state.supportReasonData);


  // console.log('hello', supportReasonList)

  // Fetch document list on component mount
  useEffect(() => {
    dispatch(SupportReasonCreators.getSupportReasonList({ startValue: 0, lastValue: 10 }));
  }, [dispatch]);

  const updateStatus = async (ele) => {
    try {
        const response = await WebService.put(
            apiEndPoints.supportreasonStatus,
            JSON.stringify({ support_reason_id: ele }),
            {
              headers: {
                token: `${userData.token}`,
              },
            }
          );
          // console.log('response', response)
          // console.log('is', ele)
          if (response.status === 200) {
            toast.success(response.data.msg, {
                position: "top-right",
            });
            dispatch(SupportReasonCreators.getSupportReasonList({ startValue: 0, lastValue: 10 }));
          } else if (response.response.status === 404) {
            toast.error(response.response.data.msg, {
                position: "top-right",
            });
            dispatch(SupportReasonCreators.getSupportReasonList({ startValue: 0, lastValue: 10 }));
          }
          else {
            toast.error('something went wrong', {
                position: "top-right",
            });
          }
    } catch (error) {
      console.error("Failed to update status:", error);
    }
  };

  const handleShowItem = () => {
    // e.preventDefault()
    setIsMediumModalOpen(true)
  }

  const handleChange = (e) => {
    // console.log('e', e)
    const { name, value } = e.target;
    setIsInputData({ ...isInputData, [name]: value });
  }

  function handleImageChange(e) {
    const { name, value } = e.target;
    getBase64(e.target.files[0]).then((result) => {
      // console.log('yes', isInputData)
      setIsInputData({
        ...isInputData, [name]: result,
      });
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    //console.log('input', isInputData)
    // dispatch(updateProfileCreators.updateProfile({ updateInput }));
    handleSubmitForm();
  };

  const handleSubmitForm = async (req, res, next,) => {
    try {
      // console.log('response', isInputData)
      // return false;
      const response = await WebService.post(
        apiEndPoints.supportreasonCreate,
        JSON.stringify(isInputData),
        {
          headers: {
            token: `${userData.token}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success(response.data.msg, {
          position: "top-right",
        });
        setIsMediumModalOpen(false)
        dispatch(SupportReasonCreators.getSupportReasonList({ startValue: 0, lastValue: 10 }));
      } else if (response.response.status === 404) {
        toast.error(response.response.data.msg, {
          position: "top-right",
        });
        dispatch(SupportReasonCreators.getSupportReasonList({ startValue: 0, lastValue: 10 }));
      }
      else {
        toast.error('something went wrong', {
          position: "top-right",
        });
      }
    } catch (error) {
      console.error("Failed to update status:", error);
    }
  }


  

  return (
    <>
      <Toaster />
      <Sidebar />
      <div className="content-wrapper">
        <section className="content-header">
          <h1>
            All Support Reason
            <small>List of Support Reason</small>
          </h1>
          <ol className="breadcrumb">
            <li>
              <Link to="/dashboard">
                <i className="fa fa-dashboard"></i> Home
              </Link>
            </li>
            <li className="active">All Support Reason</li>
          </ol>
        </section>

        <section className="content">
          <div className="row">
            <div className="col-xs-12">
              <div className="box">
                <div className="box-header">
                  <h3 className="box-title">List of All Support Reason</h3>
                </div>
                <div className="box-body" style={{ height: "500px", overflowY: "auto" }}>
                  {supportReasonLoading ? (
                    <div className="overlay">
                      <i className="fa fa-refresh fa-spin"></i>
                    </div>
                  ) : (
                    <table id="example2" className="table table-bordered table-hover">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Reason</th>
                          <th>Type</th>
                          <th>Created At</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {supportReasonList?.map((item, index) => (
                          <tr key={item?._id || index}>
                            <td>{index + 1}</td>
                            <td>
                            {_.startCase(_.toLower(item?.reason))}
                            </td>
                            <td>
                              {
                                item?.type === 'driver' ? 
                                <span className="label label-info">{item?.type}</span> : <span className="label label-primary">{item?.type}</span>
                              }
                            </td>
                            <td>{moment(item?.createdAt).format("MMMM Do YYYY, h:mm:ss a") }</td>
                            <td>
                            <SwitchComponent
                                checked={item?.status === "Active" ? true : false}
                                onChange={() => updateStatus(item?._id)}
                            />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default SupportReason;
