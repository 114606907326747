import { createReducer } from "reduxsauce";
import { DriverTypes } from "./action";


const INITIAL_STATE = {
    driverLoading: false,
    driverList: [],
    driverCount: '',
    error: ''
}


const getDriverListStart = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        driverLoading: true
    }
}

const getDriverListSuccess = (state = INITIAL_STATE, action) => {
    const { data, message } = action.payload
    
    return {
        ...state,
        driverLoading: false,
        driverList: data,
        driverCount: data.length 
    }
}

const getDriverListFailure = (state = INITIAL_STATE, action) => {
    
    return { 
        ...state,
        driverLoading: false
     }
}


const HANDLERS = {
    [DriverTypes.GET_DRIVER_LIST_START]: getDriverListStart,
    [DriverTypes.GET_DRIVER_LIST_SUCCESS]: getDriverListSuccess,
    [DriverTypes.GET_DRIVER_LIST_FAILURE]: getDriverListFailure
}

export const driverReducer = createReducer(INITIAL_STATE, HANDLERS)