import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Creators } from "../../auth/store";
import { ProfileCreators } from "./store";
import { FlexContainer } from "../../../globalStyles";
import SearchableDropdown from "../../../Common/Input/SearchInput";
import Loader from "../../../Common/Loader/Loader";
// import ModalComponent, { Modal } from "../../Common/Modal/Modal";
import ModalComponent from "../../../Common/Modal/Modal";

import { WebService } from "../../../services/axios/webServices";
import { apiEndPoints, rootConfig } from "../../../services/axios/endPoints";
import toast, { Toaster } from "react-hot-toast";
import { ToastSuccess, ToastWarning } from "../../../Common/ToastStyle/Toast";
import { sagaCatchBlockHandling } from "../../../store/utility/sagaErrorHandling";
import { getUserDataFromLocal } from "../../../shared/utility/helper";
import moment from "moment";
import { ErrorP } from "../../../Common/Modal/Modal";
import Select from 'react-select';
import AppHelmet from "../Helmet/Helmet";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Header from "./Section/Header"
import Footer from "./Section/Footer";
import Sidebar from "./Section/Sidebar"
import { PassengerCreators } from "./Passenger/store";
import { DriverCreators } from "./Driver/store"
import { OwnerCreators } from "./Owner/store";
import { Chart } from "react-google-charts";



export const Dashboard = () => {
  const userData = getUserDataFromLocal();
  const navigate = useNavigate();
  const { profileData, loading, dashboardLoading, dashboardData } = useSelector(
    (state) => state.profileData
  );


  const data_pie = [
    ["Task", "Hours per Day"],
    ["Driver", 9],
    ["Owner", 2],
    ["Customer", 2]
  ];
  const options = {
    title: "My Daily Activities",
  };

  console.log('dashboardData', dashboardData)

  const dispatch = useDispatch();
  const [seletedItem, setSelectedItem] = useState([]);

  const useAuth = () => {
    const user = localStorage.getItem("user");
    if (user) {
      return true;
    } else {
      return false;
    }
  };
  const auth = useAuth();

  const handleLogout = () => {
    dispatch(Creators.onLogout());
    navigate("/");
  };



  useEffect(() => {
    dispatch(ProfileCreators.getProfile());
    dispatch(ProfileCreators.getDashboard());
  }, []);


  return (
    <>
      <Toaster />
      <AppHelmet title="Travel Teacher" description="Travel Teacher Ride Book" />
      <Sidebar />
      <div className="content-wrapper" style={{ height: '500px', overflowY: 'auto' }}>
        <section className="content-header">
          <h1>
            Dashboard
            <small>Control panel</small>
          </h1>
          <ol className="breadcrumb">
            <li><Link to="/"><i className="fa fa-dashboard"></i> Home</Link></li>
            <li className="active">Dashboard</li>
          </ol>
        </section>

        <section className="content">
          <div className="row">
            <div className="col-lg-3 col-xs-6">
              <div className="small-box bg-aqua">
                <div className="inner">
                  <h3>{dashboardData?.count_user}</h3>

                  <p>New Passengers</p>
                </div>
                <div className="icon">
                  <i className="ion ion-person-add"></i>
                </div>
                <Link to="#" className="small-box-footer">More info <i className="fa fa-arrow-circle-right"></i></Link>
              </div>
            </div>
            <div className="col-lg-3 col-xs-6">
              <div className="small-box bg-green">
                <div className="inner">
                  <h3>{dashboardData?.count_driver}<sup style={{ fontSize: '20px' }}></sup></h3>

                  <p>New Drivers</p>
                </div>
                <div className="icon">
                  <i className="ion ion-person-add"></i>
                </div>
                <Link to="#" className="small-box-footer">More info <i className="fa fa-arrow-circle-right"></i></Link>
              </div>
            </div>
            <div className="col-lg-3 col-xs-6">
              <div className="small-box bg-yellow">
                <div className="inner">
                  <h3>{dashboardData?.count_owner}</h3>

                  <p>New Owners</p>
                </div>
                <div className="icon">
                  <i className="ion ion-person-add"></i>
                </div>
                <Link to="#" className="small-box-footer">More info <i className="fa fa-arrow-circle-right"></i></Link>
              </div>
            </div>
            <div className="col-lg-3 col-xs-6">
              <div className="small-box bg-red">
                <div className="inner">
                  <h3>{dashboardData?.count_booking}</h3>

                  <p>New Bookings</p>
                </div>
                <div className="icon">
                  <i className="ion ion-cart-plus"></i>
                </div>
                <Link to="#" className="small-box-footer">More info <i className="fa fa-arrow-circle-right"></i></Link>
              </div>
            </div>
          </div>
          <div className="row">
            <section className="col-lg-7 connectedSortable">
              {/* <div className="nav-tabs-custom">
                <ul className="nav nav-tabs pull-right">
                  <li className="active"><Link to="#revenue-chart" data-toggle="tab">Area</Link></li>
                  <li><Link to="#sales-chart" data-toggle="tab">Donut</Link></li>
                  <li className="pull-left header"><i className="fa fa-inbox"></i> Sales</li>
                </ul>
                <div className="tab-content no-padding">
                  <div className="chart tab-pane active" id="revenue-chart" style={{position: 'relative', height: '300px'}}></div>
                  <div className="chart tab-pane" id="sales-chart" style={{position: 'relative', height: '300px'}}></div>
                </div>
              </div> */}

              <div class="box box-info">
                <div class="box-header with-border">
                  <h3 class="box-title">Latest Driver</h3>

                  <div class="box-tools pull-right">
                    <button type="button" class="btn btn-box-tool" data-widget="collapse"><i class="fa fa-minus"></i>
                    </button>
                    <button type="button" class="btn btn-box-tool" data-widget="remove"><i class="fa fa-times"></i></button>
                  </div>
                </div>
                <div class="box-body">
                  <div class="table-responsive">
                    <table class="table no-margin">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Name</th>
                          <th>Phone</th>
                          <th>Email Address</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dashboardData?.new_driver?.length > 0 && dashboardData?.new_driver.map((item, index) => {
                          return (
                              <tr key={index}>
                                <td>{index+1}</td>
                                <td>{item?.name}</td>
                                <td>{item?.phone}</td>
                                <td>{item?.email}</td>
                                <td>{moment(item?.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</td>
                              </tr>
                          )
                        })}
                        
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="box-footer clearfix">
                  <Link to="/dashboard/driver" class="btn btn-sm btn-default btn-flat pull-right">View All Driver</Link>
                </div>
              </div>
              <div class="box box-info">
                <div class="box-header with-border">
                  <h3 class="box-title">Latest Customer</h3>

                  <div class="box-tools pull-right">
                    <button type="button" class="btn btn-box-tool" data-widget="collapse"><i class="fa fa-minus"></i>
                    </button>
                    <button type="button" class="btn btn-box-tool" data-widget="remove"><i class="fa fa-times"></i></button>
                  </div>
                </div>
                <div class="box-body">
                  <div class="table-responsive">
                    <table class="table no-margin">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Name</th>
                          <th>Phone</th>
                          <th>Email Address</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dashboardData?.new_user?.length > 0 && dashboardData?.new_user.map((item, index) => {
                          return (
                              <tr key={index}>
                                <td>{index+1}</td>
                                <td>{item?.name}</td>
                                <td>{item?.phone}</td>
                                <td>{item?.email}</td>
                                <td>{moment(item?.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</td>
                              </tr>
                          )
                        })}
                        
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="box-footer clearfix">
                  <Link to="/dashboard/passenger" class="btn btn-sm btn-default btn-flat pull-right">View All Customer</Link>
                </div>
              </div>
              <div class="box box-info">
                <div class="box-header with-border">
                  <h3 class="box-title">Latest Owner</h3>

                  <div class="box-tools pull-right">
                    <button type="button" class="btn btn-box-tool" data-widget="collapse"><i class="fa fa-minus"></i>
                    </button>
                    <button type="button" class="btn btn-box-tool" data-widget="remove"><i class="fa fa-times"></i></button>
                  </div>
                </div>
                <div class="box-body">
                  <div class="table-responsive">
                    <table class="table no-margin">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Name</th>
                          <th>Phone</th>
                          <th>Email Address</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dashboardData?.new_owner?.length > 0 && dashboardData?.new_owner.map((item, index) => {
                          return (
                              <tr key={index}>
                                <td>{index+1}</td>
                                <td>{item?.name}</td>
                                <td>{item?.phone}</td>
                                <td>{item?.email}</td>
                                <td>{moment(item?.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</td>
                              </tr>
                          )
                        })}
                        
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="box-footer clearfix">
                  <Link to="/dashboard/owner" class="btn btn-sm btn-default btn-flat pull-right">View All Owner</Link>
                </div>
              </div>

            </section>
            <section className="col-lg-5 connectedSortable">

              <div className="box box-solid bg-teal-gradient">
                <div className="box-header">
                  <i className="fa fa-th"></i>

                  <h3 className="box-title">Sales Graph</h3>

                  <div className="box-tools pull-right">
                    <button type="button" className="btn bg-teal btn-sm" data-widget="collapse"><i className="fa fa-minus"></i>
                    </button>
                    <button type="button" className="btn bg-teal btn-sm" data-widget="remove"><i className="fa fa-times"></i>
                    </button>
                  </div>
                </div>
                <div className="box-body border-radius-none">
                  <Chart
                    chartType="PieChart"
                    data={data_pie}
                    options={options}
                    width={"100%"}
                    height={"600px"}
                  />
                </div>
              </div>

            </section>
          </div>

        </section>
      </div>
      <Footer />
    </>
  );
};

