import { takeLatest, takeEvery } from "redux-saga/effects";
import { Types } from "./actions";
import { loginSaga, onLogoutSaga } from "../view/auth/store/saga";
import { getProfileSaga } from "../view/Pages/Dashboard/store";

import {
  getDashboardSaga,
} from "../view/Pages/Dashboard/store/saga";

import { getPassengerListSaga } from "../view/Pages/Dashboard/Passenger/store/saga";

import { getDriverListSaga } from "../view/Pages/Dashboard/Driver/store";

import { getOwnerListSaga } from "../view/Pages/Dashboard/Owner/store";

import { getBookingListSaga } from "../view/Pages/Dashboard/Booking/store";

import { getDocumentListSaga } from "../view/Pages/Dashboard/Document/store";

import { getSupportTicketListSaga } from "../view/Pages/Dashboard/SupportTicket/store";

import { getSupportReasonListSaga } from "../view/Pages/Dashboard/SupportReason/store";

import { getCancelReasonListSaga } from "../view/Pages/Dashboard/CancelReason/store";

import { getVehicleListSaga } from "../view/Pages/Dashboard/Vehicle/store";

import { getVehicleModelListSaga } from "../view/Pages/Dashboard/VehicleModel/store";

import { getVehicleTypesListSaga } from "../view/Pages/Dashboard/VehicleTypes/store";



export function* watchAuth() {
  yield takeLatest(Types.LOGIN, loginSaga);
  yield takeLatest(Types.ON_LOGOUT, onLogoutSaga);
  yield takeLatest(Types.GET_PROFILE, getProfileSaga);
  yield takeLatest(Types.GET_DASHBOARD, getDashboardSaga);
  yield takeLatest(Types.GET_PASSENGER_LIST, getPassengerListSaga);
  yield takeLatest(Types.GET_DRIVER_LIST, getDriverListSaga);
  yield takeLatest(Types.GET_OWNER_LIST, getOwnerListSaga);
  yield takeLatest(Types.GET_BOOKING_LIST, getBookingListSaga);
  yield takeLatest(Types.GET_DOCUMENT_LIST, getDocumentListSaga);
  yield takeLatest(Types.GET_SUPPORT_TICKET_LIST, getSupportTicketListSaga);
  yield takeLatest(Types.GET_SUPPORT_REASON_LIST, getSupportReasonListSaga);
  yield takeLatest(Types.GET_CANCEL_REASON_LIST, getCancelReasonListSaga);
  yield takeLatest(Types.GET_VEHICLE_LIST, getVehicleListSaga);
  yield takeLatest(Types.GET_VEHICLE_MODEL_LIST, getVehicleModelListSaga)
  yield takeLatest(Types.GET_VEHICLE_TYPES_LIST, getVehicleTypesListSaga)
}
