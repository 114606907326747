import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Creators } from "../../../auth/store";
import { OwnerCreators, OwnerTypes } from "./store";
import { getUserDataFromLocal } from "../../../../shared/utility/helper";
import Footer from "../Section/Footer";
import Sidebar from "../Section/Sidebar";
import toast, { Toaster } from "react-hot-toast";
import _ from "lodash";
import moment from 'moment';
import Loader from "../../../../Common/Loader/Loader";
import { Modal } from "react-bootstrap";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import ModalComponent from "../../../../Common/Modal/Modal";
import { sagaCatchBlockHandling } from "../../../../store/utility/sagaErrorHandling";
import { WebService } from "../../../../services/axios/webServices";
import { apiEndPoints, rootConfig } from "../../../../services/axios/endPoints";
import formatPhoneNumber from "../../../../Common/PhoneNumber/PhoneNumber";




export const ShowOwner = () => {

    const userData = getUserDataFromLocal();
    const { owner_id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch(); 

    const [isData, setIsData] = useState([])

    const { ownerLoading, ownerList } = useSelector((state) => state.ownerData);
    
    console.log('driverList', ownerList)


    useEffect(() => {
        dispatch(OwnerCreators.getOwnerList({ "startValue": 0, "lastValue": 10 }));
        handleOwnerPreview();
    }, []);
    console.log('token', userData.token)

    const handleOwnerPreview = async () => {
        // setLoading(true);

        try {
            const response = await WebService.post(
                apiEndPoints.ownerPreview,
                JSON.stringify({owner_id: owner_id}),
                {
                  headers: {
                    token: `${userData.token}`,
                  },
                }
              );
            // const { data } = response;
            // console.log(response)
            if (response.status === 200) {
                setIsData(response.data.data)
            } else {
                throw response;
            }
        } catch (e) {
        const error = sagaCatchBlockHandling(e);

        // console.log(error, "error000");
        }
    };
    

    return (
        <>
            <Toaster />
            <Sidebar />
            <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        {_.startCase(_.toLower(isData?.role))}
                        {/* <small>List of passengers</small> */}
                    </h1>
                    <ol className="breadcrumb">
                        <li><Link to="/dashboard"><i className="fa fa-dashboard"></i> Home</Link></li>
                        <li><Link to="/dashboard/owner">Owner List</Link></li>
                        
                        <li className="active">{_.startCase(_.toLower(isData?.name))}</li>
                    </ol>
                </section>

                
                <section className="content">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="box">
                                <div className="box-header">
                                    <h3 className="box-title">{isData?.name}</h3>
                                </div>
                                
                                <div className="box-body">
                                    <table id="example2" className="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>Profile</th>
                                                <th>
                                                    <div className="user-block">
                                                        <img className="img-circle" src={(isData?.profile == 0) ? rootConfig.base_url+'uploads/no_image.png' : rootConfig.base_url+isData?.profile} alt="User Image" />
                                                    </div>
                                                </th>
                                            </tr>
                                            <tr>
                                                <th>Name</th>
                                                <td>{isData?.name}</td>
                                            </tr>
                                            <tr>
                                                <th>Email Address</th>
                                                <td>{isData?.email}</td>
                                            </tr>
                                            <tr>
                                                <th>Phone Number</th>
                                                <td>{formatPhoneNumber(isData?.phone)}</td>
                                            </tr>
                                            <tr>
                                                <th>Country</th>
                                                <td>{isData?.country_code}</td>
                                            </tr>
                                            <tr>
                                                <th>Referral Code</th>
                                                <td>{isData?.referral_code}</td>
                                            </tr>
                                            <tr>
                                                <th>Verified</th>
                                                <td>{(isData?.verified) ? <span className="label label-success">True</span> : <span className="label label-danger">False</span>}</td>
                                            </tr>
                                            <tr>
                                                <th>Role</th>
                                                <td><span className="label label-info">{isData?.role}</span></td>
                                            </tr>
                                            <tr>
                                                <th>Languages</th>
                                                <td>{isData?.languages}</td>
                                            </tr>
                                            <tr>
                                                <th>Status</th>
                                                <td>{(isData?.status == 'Active') ? <span className="label label-success">Active</span> : <span className="label label-success">Inactive</span>}</td>
                                            </tr>
                                            <tr>
                                                <th>Suspend</th>
                                                <td>{(isData?.suspend == 'Inactive') ? <span className="label label-success">Inactive</span> : <span className="label label-success">Active</span>}</td>
                                            </tr>
                                            <tr>
                                                <th>Created At</th>
                                                <td>{moment(isData?.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </>
    )
}