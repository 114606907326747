import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getUserDataFromLocal } from "../../../../shared/utility/helper";
import Footer from "../Section/Footer";
import Sidebar from "../Section/Sidebar";
import toast, { Toaster } from "react-hot-toast";
import _ from "lodash";
import moment from "moment";
import { WebService } from "../../../../services/axios/webServices";
import { apiEndPoints, rootConfig } from "../../../../services/axios/endPoints";
import formatPhoneNumber from "../../../../Common/PhoneNumber/PhoneNumber";

export const Setting = () => {
    const userData = getUserDataFromLocal();
    const { passenger_id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [isData, setIsData] = useState([]);
    const [isRideData, setIsRideData] = useState([]);


    useEffect(() => {
        handleSettingPreview();
    }, []);

    const handleSettingPreview = async () => {
        try {
            const response = await WebService.post(
                apiEndPoints.passengerPreview,
                JSON.stringify({ customer_id: passenger_id }),
                { headers: { token: `${userData.token}` } }
            );

            if (response.status === 200) {
                setIsData(response.data.data);
            } else {
                throw response;
            }
        } catch (e) {
            toast.error("Error loading customer details.");
        }
    };

    return (
        <>
            <Toaster />
            <Sidebar />
            <div className="content-wrapper">
                <section className="content-header">
                    <h1>Customer Details</h1>
                    <ol className="breadcrumb">
                        <li>
                            <Link to="/dashboard">
                                <i className="fa fa-dashboard"></i> Home
                            </Link>
                        </li>
                        <li>
                            <Link to="/dashboard/passenger">Passenger</Link>
                        </li>
                        <li className="active">{_.startCase(_.toLower(isData?.name || "Unknown"))}</li>
                    </ol>
                </section>

                <section className="content" style={{ height: "500px", overflowY: "auto" }}>
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="box">
                                <div className="box-header">
                                    <h3 className="box-title">{isData?.name}</h3>
                                    <div className="col-xs-12 text-right">
                                        <Link to="/dashboard/passenger" className="btn btn-default btn-sm">
                                            <i className="fa fa-arrow-left"></i>&nbsp; Back
                                        </Link>
                                    </div>
                                </div>
                                <div className="box-body">
                                    <table id="example2" className="table table-bordered table-hover">
                                        <tbody>
                                            <tr>
                                                <th>Profile</th>
                                                <td>
                                                    <div className="user-block">
                                                        <img
                                                            className="img-circle"
                                                            src={
                                                                isData?.profile === 0
                                                                    ? rootConfig.base_url + "uploads/no_image.png"
                                                                    : rootConfig.base_url + isData?.profile
                                                            }
                                                            alt="User"
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Name</th>
                                                <td>{isData?.name || "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Email Address</th>
                                                <td>{isData?.email || "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Phone Number</th>
                                                <td>{formatPhoneNumber(isData?.phone) || "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Country</th>
                                                <td>{isData?.country_code || "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Referral Code</th>
                                                <td>{isData?.referral_code || "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Verified</th>
                                                <td>
                                                    {isData?.verified ? (
                                                        <span className="label label-success">True</span>
                                                    ) : (
                                                        <span className="label label-warning">False</span>
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Role</th>
                                                <td>
                                                    <span className="label label-info">{isData?.role || "N/A"}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Languages</th>
                                                <td>{isData?.languages || "N/A"}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="box">
                                <div className="box-header">
                                    <h3 className="box-title">Booking List</h3>
                                </div>
                                <div className="box-body" style={{ height: "500px", overflowY: "auto" }}>
                                    <table id="example2" className="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Order ID</th>
                                                <th>Customer Name</th>
                                                <th>Booking Date</th>
                                                <th>Booking Status</th>
                                                <th>Payment Status</th>
                                                <th>Total</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </>
    );
};
