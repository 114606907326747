import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { CancelReasonCreators } from "./store";
import { getUserDataFromLocal } from "../../../../shared/utility/helper";
import Sidebar from "../Section/Sidebar";
import Footer from "../Section/Footer";
import toast, { Toaster } from "react-hot-toast";
import _ from "lodash";
import moment from "moment";
import Loader from "../../../../Common/Loader/Loader";
import SwitchComponent from "../../../../Common/SwitchComponent/SwitchComponent";
import formatPhoneNumber from "../../../../Common/PhoneNumber/PhoneNumber";
import { apiEndPoints, rootConfig } from "../../../../services/axios/endPoints";
import { WebService } from "../../../../services/axios/webServices";
import { getBase64 } from "../../../../shared/utility/getBase64";
import Select from "react-select";
import Modal from "../../../../Common/Modal/Modal"

export const CancelReason = () => {
  const userData = getUserDataFromLocal();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isMediumModalOpen, setIsMediumModalOpen] = useState(false);
  const closeMediumModal = () => setIsMediumModalOpen(false);
  const [isInputData, setIsInputData] = useState({})

  const { cancelReasonLoading, cancelReasonList } = useSelector((state) => state.cancelReasonData);


  // console.log('hello', cancelReasonList)

  // Fetch document list on component mount
  useEffect(() => {
    dispatch(CancelReasonCreators.getCancelReasonList({ startValue: 0, lastValue: 10 }));
  }, [dispatch]);

  const updateStatus = async (ele) => {
    try {
      const response = await WebService.put(
        apiEndPoints.cancelreasonStatus,
        JSON.stringify({ cancel_reason_id: ele }),
        {
          headers: {
            token: `${userData.token}`,
          },
        }
      );
      // console.log('response', response)
      // console.log('is', ele)
      if (response.status === 200) {
        toast.success(response.data.msg, {
          position: "top-right",
        });
        dispatch(CancelReasonCreators.getCancelReasonList({ startValue: 0, lastValue: 10 }));
      } else if (response.response.status === 404) {
        toast.error(response.response.data.msg, {
          position: "top-right",
        });
        dispatch(CancelReasonCreators.getCancelReasonList({ startValue: 0, lastValue: 10 }));
      }
      else {
        toast.error('something went wrong', {
          position: "top-right",
        });
      }
    } catch (error) {
      console.error("Failed to update status:", error);
    }
  };

  const handleShowItem = () => {
    // e.preventDefault()
    setIsMediumModalOpen(true)
  }

  const handleChange = (e) => {
    // console.log('e', e)
    const { name, value } = e.target;
    setIsInputData({ ...isInputData, [name]: value });
  }

  function handleImageChange(e) {
    const { name, value } = e.target;
    getBase64(e.target.files[0]).then((result) => {
      // console.log('yes', isInputData)
      setIsInputData({
        ...isInputData, [name]: result,
      });
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    //console.log('input', isInputData)
    // dispatch(updateProfileCreators.updateProfile({ updateInput }));
    handleSubmitForm();
  };

  const handleSubmitForm = async (req, res, next,) => {
    try {
      // console.log('response', isInputData)
      // return false;
      const response = await WebService.post(
        apiEndPoints.cancelreasonCreate,
        JSON.stringify(isInputData),
        {
          headers: {
            token: `${userData.token}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success(response.data.msg, {
          position: "top-right",
        });
        setIsMediumModalOpen(false)
        dispatch(CancelReasonCreators.getCancelReasonList({ startValue: 0, lastValue: 10 }));
      } else if (response.response.status === 404) {
        toast.error(response.response.data.msg, {
          position: "top-right",
        });
        dispatch(CancelReasonCreators.getCancelReasonList({ startValue: 0, lastValue: 10 }));
      }
      else {
        toast.error('something went wrong', {
          position: "top-right",
        });
      }
    } catch (error) {
      console.error("Failed to update status:", error);
    }
  }




  return (
    <>
      <Toaster />
      <Sidebar />
      <Modal
        isOpen={isMediumModalOpen}
        onClose={closeMediumModal}
        title={`Add Cancel Reason`}
        size="Medium"
        footer={
          <>
            <button onClick={closeMediumModal} style={{ background: 'gray', color: 'white' }}>
              Close
            </button>
          </>
        }
      >
        <div className="box box-primary">
          <div className="box-header with-border">
            {/* <h3 className="box-title">Quick Example</h3> */}
          </div>
          <form onSubmit={handleSubmit}>
            <div className="box-body">
              <div className="form-group">
                <label htmlFor="reason">Reasone Title</label>
                <input type="text" className="form-control" id="reason" placeholder="Enter reason" name="reason"
                  onChange={handleChange} />
              </div>
            </div>

            <div className="box-footer">
              <button type="submit" className="btn btn-primary">Submit</button>
            </div>
          </form>
        </div>
      </Modal>
      <div className="content-wrapper">
        <section className="content-header">
          <h1>
            All Cancel Reason
            <small>List of Cancel Reason</small>
          </h1>
          <ol className="breadcrumb">
            <li>
              <Link to="/dashboard">
                <i className="fa fa-dashboard"></i> Home
              </Link>
            </li>
            <li className="active">All Cancel Reason</li>
          </ol>
        </section>

        <section className="content">
          <div className="row">
            <div className="col-xs-12">
              <div className="box">
                <div className="box-header">
                  <h3 className="box-title">List of All Cancel Reason</h3>
                  <button type="button" className="btn btn-info btn-sm pull-right"
                    onClick={() => handleShowItem()}
                  >
                    <i className="fa fa-plus"></i>&nbsp;
                    Add Cancel Reason
                  </button>
                </div>
                <div className="box-body" style={{ height: "500px", overflowY: "auto" }}>
                  {cancelReasonLoading ? (
                    <div className="overlay">
                      <i className="fa fa-refresh fa-spin"></i>
                    </div>
                  ) : (
                    <table id="example2" className="table table-bordered table-hover">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Reason</th>
                          <th>Created At</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cancelReasonList?.map((item, index) => (
                          <tr key={item?._id || index}>
                            <td>{index + 1}</td>
                            <td>
                              {_.startCase(_.toLower(item?.reason))}
                            </td>
                            <td>{moment(item?.createdAt).format("MMMM Do YYYY, h:mm:ss a")}</td>
                            <td>
                              <SwitchComponent
                                checked={item?.status === "Active" ? true : false}
                                onChange={() => updateStatus(item?._id)}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default CancelReason;
