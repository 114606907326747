import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Creators } from "../../../auth/store";
import { DriverCreators } from "./store";
import { getUserDataFromLocal } from "../../../../shared/utility/helper";
import Footer from "../Section/Footer";
import Sidebar from "../Section/Sidebar";
import { Toaster, toast } from "react-hot-toast";
import _ from "lodash";
import moment from 'moment';
import Loader from "../../../../Common/Loader/Loader";
import Modal from "../../../../Common/Modal/Modal"
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import ModalComponent from "../../../../Common/Modal/Modal";
import { apiEndPoints, rootConfig } from "../../../../services/axios/endPoints";
import formatPhoneNumber from "../../../../Common/PhoneNumber/PhoneNumber";
import { WebService } from "../../../../services/axios/webServices";
import SwitchComponent from "../../../../Common/SwitchComponent/SwitchComponent";




export const Driver = () => {

    const userData = getUserDataFromLocal();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Pagination state
    const [startValue, setStartValue] = useState(0)
    const [lastValue, setLastValue] = useState(0)

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(10); // Number of items per page
    const [totalPages, setTotalPages] = useState(0);

    const [isDriverObj, setIsDriverObj] = useState({})

    const [isMediumModalOpen, setIsMediumModalOpen] = useState(false);
    const closeMediumModal = () => setIsMediumModalOpen(false);


    const { driverLoading, driverList } = useSelector((state) => state.driverData);

    // console.log('driverList', driverList)


    // useEffect(() => {
    //     dispatch(DriverCreators.getDriverList({ "startValue": 0, "lastValue": 10 }));

    // }, []);

    useEffect(() => {
        const startValue = (currentPage - 1) * pageSize;
        const lastValue = startValue + pageSize;
        setStartValue(startValue)
        setLastValue(lastValue)
        // Dispatch API call with startValue and lastValue
        dispatch(DriverCreators.getDriverList({ startValue, lastValue }));

        // Set total pages based on the data from the API response (mocked for now)
        // Example: Update this logic based on your actual data structure
        setTotalPages(20); // Replace with API response total page count if available
    }, [currentPage, pageSize, dispatch]);

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage((prev) => prev - 1);
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage((prev) => prev + 1);
        }
    };


    const handleShowItem = async (item) => {

        // openModal(item)
        setIsMediumModalOpen(true)
        // console.log('hello', item)
        setIsDriverObj(item)
    }

    const updateStatus = async (ele) => {
        try {
            const response = await WebService.post(
                apiEndPoints.driverStatus,
                JSON.stringify({ driver_id: ele }),
                {
                    headers: {
                        token: `${userData.token}`,
                    },
                }
            );
            //   console.log('response', response)
            if (response.status === 200) {
                toast.success(response.data.msg, {
                    position: "top-right",
                });
                dispatch(DriverCreators.getDriverList({ startValue: 0, lastValue: 10 }));
            } else if (response.response.status === 404) {
                toast.error(response.response.data.msg, {
                    position: "top-right",
                });
                dispatch(DriverCreators.getDriverList({ startValue: 0, lastValue: 10 }));
            }
            else {
                toast.error('something went wrong', {
                    position: "top-right",
                });
            }
        } catch (error) {
            console.error("Failed to update status:", error);
        }
    };

    const updateSuspend = async (ele) => {
        try {
            const response = await WebService.post(
                apiEndPoints.driverSuspend,
                JSON.stringify({ driver_id: ele }),
                {
                    headers: {
                        token: `${userData.token}`,
                    },
                }
            );
            //   console.log('response', response)
            if (response.status === 200) {
                toast.success(response.data.msg, {
                    position: "top-right",
                });
                dispatch(DriverCreators.getDriverList({ startValue: 0, lastValue: 10 }));
            } else if (response.response.status === 404) {
                toast.error(response.response.data.msg, {
                    position: "top-right",
                });
                dispatch(DriverCreators.getDriverList({ startValue: 0, lastValue: 10 }));
            }
            else {
                toast.error('something went wrong', {
                    position: "top-right",
                });
            }
        } catch (error) {
            console.error("Failed to update status:", error);
        }
    };

    const updateDocumentVerify = async (driver_id, document_id, status) => {
        try {
            // console.log('driver', driver_id, document_id, status)
            // return false;
            const response = await WebService.put(
                apiEndPoints.driverDocumentStatus,
                JSON.stringify({ user_id: driver_id, document_id: document_id, status: status }),
                {
                    headers: {
                        token: `${userData.token}`,
                    },
                }
            );
            console.log('response', response)
            if (response.status === 200) {
                toast.success(response.data.msg, {
                    position: "top-right",
                });
                dispatch(DriverCreators.getDriverList({ startValue: 0, lastValue: 10 }));
                // handleShowItem(response.data.data)

            } else if (response.response.status === 404) {
                toast.error(response.response.data.msg, {
                    position: "top-right",
                });
                dispatch(DriverCreators.getDriverList({ startValue: 0, lastValue: 10 }));
            }
            else {
                toast.error('something went wrong', {
                    position: "top-right",
                });
            }
        } catch (error) {
            console.error("Failed to update status:", error);
        }
    }

    const updateVehicleVerify = async (user_id, vehicle_id, status) => {
        try {
            // console.log('driver', driver_id, document_id, status)
            // return false;
            const response = await WebService.put(
                apiEndPoints.driverVehicleStatus,
                JSON.stringify({ user_id: user_id, vehicle_id: vehicle_id, status: status }),
                {
                    headers: {
                        token: `${userData.token}`,
                    },
                }
            );
            console.log('response', response)
            if (response.status === 200) {
                toast.success(response.data.msg, {
                    position: "top-right",
                });
                dispatch(DriverCreators.getDriverList({ startValue: 0, lastValue: 10 }));
                // handleShowItem(response.data.data)

            } else if (response.response.status === 404) {
                toast.error(response.response.data.msg, {
                    position: "top-right",
                });
                dispatch(DriverCreators.getDriverList({ startValue: 0, lastValue: 10 }));
            }
            else {
                toast.error('something went wrong', {
                    position: "top-right",
                });
            }
        } catch (error) {
            console.error("Failed to update status:", error);
        }
    }



    return (
        <>
            <Toaster />

            <Sidebar />
            <Modal
                isOpen={isMediumModalOpen}
                onClose={closeMediumModal}
                title={`${isDriverObj?.name} Driver Details`}
                size="Medium"
                footer={
                    <>
                        <button onClick={closeMediumModal} style={{ background: 'gray', color: 'white' }}>
                            Close
                        </button>
                    </>
                }
            >
                <div className="box-body" style={{ height: '500px', overflowY: 'auto' }}>
                    <div className="user-block">
                        <img className="img-circle" src={(isDriverObj?.profile == 0) ? rootConfig.base_url + 'uploads/no_image.png' : rootConfig.base_url + isDriverObj?.profile} alt="User Image" />
                        <span className="username"><Link to="#">{_.startCase(_.toLower(isDriverObj?.name))}</Link></span>
                        <span className="email"><Link to="#">{isDriverObj?.email}</Link></span>
                        <span className="description">Register - {moment(isDriverObj?.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</span>
                    </div>
                    <table id="example2" className="table table-bordered table-hover scrollable">
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>Name</th>
                                <th>Document</th>
                                <th>Verify</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                isDriverObj?.documents?.length > 0 ? (
                                    isDriverObj.documents.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item?.document_type || "No type Provided"}</td>
                                            <td>{item?.name || "No Name Provided"}</td>

                                            <td>
                                                {item?.document_url?.length > 0 ? (
                                                    item.document_url.map((doc, docIndex) => (
                                                        <img
                                                            key={docIndex}
                                                            className="img-circle"
                                                            src={
                                                                doc === 0
                                                                    ? rootConfig.image_url + "uploads/no_image.png"
                                                                    : rootConfig.image_url + doc
                                                            }
                                                            alt="User Document" height="100px" width="100px"
                                                        />
                                                    ))
                                                ) : (
                                                    <span>No Documents Available</span>
                                                )}
                                            </td>
                                            <td>
                                                <SwitchComponent
                                                    checked={item?.status === "approved" ? true : false}
                                                    onChange={() => updateDocumentVerify(isDriverObj?._id, item?._id, item?.status === "approved" ? "rejected" : "approved")}
                                                />
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="3" style={{ textAlign: "center" }}>
                                            No Documents Found
                                        </td>
                                    </tr>
                                )
                            }




                        </tbody>
                    </table>
                    <table id="example2" className="table table-bordered table-hover scrollable">
                        <thead>
                            <tr>
                                <th>Verify</th>
                                <td>
                                    <SwitchComponent
                                        checked={isDriverObj?.vehicle?.status === "approved" ? true : false}
                                        onChange={() => {
                                            const currentStatus = isDriverObj?.vehicle?.status;
                                            let newStatus;

                                            // Handle the transition based on current status
                                            if (currentStatus === "approved") {
                                                newStatus = "rejected";
                                            } else if (currentStatus === "rejected") {
                                                newStatus = "approved";
                                            } else if (currentStatus === "pending") {
                                                // Example: automatically approve the vehicle when it's pending
                                                newStatus = "approved"; // Or could be 'rejected' based on your logic
                                            }

                                            // Update the vehicle status
                                            updateVehicleVerify(isDriverObj?._id, isDriverObj?.vehicle?._id, newStatus);
                                        }}
                                    />

                                </td>
                            </tr>
                            <tr>
                                <th>Vehicle Image</th>
                                <td>
                                    <img
                                        className="img-circle"
                                        src={
                                            isDriverObj?.vehicle?.image !== ""
                                                ? rootConfig.image_url + "no_image.png"
                                                : rootConfig.image_url + isDriverObj?.vehicle?.image
                                        }
                                        alt="User Document" height="100px" width="100px"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>Brand Name</th>
                                <td>{isDriverObj?.vehicle?.brand_name}</td>
                            </tr>
                            <tr>
                                <th>Model Name</th>
                                <td>{isDriverObj?.vehicle?.model_name}</td>
                            </tr>
                            <tr>
                                <th>Vehicle Number</th>
                                <td>{isDriverObj?.vehicle?.vehicle_number}</td>
                            </tr>
                            <tr>
                                <th>Vehicle Color</th>
                                <td>{isDriverObj?.vehicle?.vehicle_color}</td>
                            </tr>
                        </thead>
                    </table>
                </div>
            </Modal>

            <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        All Driver
                        <small>List of drivers</small>
                    </h1>
                    <ol className="breadcrumb">
                        <li><Link to="/dashboard"><i className="fa fa-dashboard"></i> Home</Link></li>

                        <li className="active">All Drivers</li>
                    </ol>
                </section>


                <section className="content">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="box" style={{ height: '500px', overflowY: 'auto' }}>
                                <div className="box-header">
                                    <h3 className="box-title">List of All Drivers</h3>
                                </div>

                                <div className="box-body">
                                    <table id="example2" className="table table-bordered table-hover scrollable">
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Name</th>
                                                <th>Email Address</th>
                                                <th>Phone Number</th>
                                                <th>Status</th>
                                                <th>Suspend</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                driverLoading ? (
                                                    <div className="overlay">
                                                        <i className="fa fa-refresh fa-spin"></i>
                                                    </div>
                                                ) : driverList?.map((item, index) => {
                                                    return (
                                                        <tr key={index + 1}>
                                                            <td>{index + 1}</td>
                                                            <td>
                                                                <div className="user-block" key={index + 1}>
                                                                    <img className="img-circle" src={(item?.profile == 0) ? rootConfig.base_url + 'uploads/no_image.png' : rootConfig.base_url + item?.profile} alt="User Image" />
                                                                    <span className="username"><Link to="#">{_.startCase(_.toLower(item?.name))}</Link></span>
                                                                    <span className="description">Register - {moment(item?.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {item.email}
                                                            </td>
                                                            <td>
                                                                {formatPhoneNumber(item.phone)}
                                                            </td>
                                                            <td>
                                                                <SwitchComponent
                                                                    checked={item?.status === "Active" ? true : false}
                                                                    onChange={() => updateStatus(item?._id)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <SwitchComponent
                                                                    checked={item?.suspend === "Active" ? true : false}
                                                                    onChange={() => updateSuspend(item?._id)}
                                                                />
                                                            </td>
                                                            <td>

                                                                <Link to={`/dashboard/driver/${item._id}`} type="button" className="btn btn-default btn-sm"
                                                                // onClick={() => handleShowItem(item)} 
                                                                >
                                                                    <i className="fa fa-eye"></i>&nbsp;
                                                                    Show
                                                                </Link>
                                                            </td>
                                                            <td>

                                                                <Link to="#" type="button" className="btn btn-default btn-sm"
                                                                    onClick={() => handleShowItem(item)}
                                                                >
                                                                    <i className="fa fa-check-square-o"></i>&nbsp;
                                                                    Verify Document
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="box-footer">
                                    <div className="pagination-container">
                                        <nav aria-label="Page navigation">
                                            <ul className="pagination justify-content-center">
                                                <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                                                    <button
                                                        className="page-link"
                                                        onClick={handlePrevious}
                                                        disabled={currentPage === 1}
                                                    >
                                                        Previous
                                                    </button>
                                                </li>
                                                <li className="page-item disabled">
                                                    <span className="page-link">
                                                        Page {currentPage} of {totalPages}
                                                    </span>
                                                </li>
                                                <li
                                                    className={`page-item ${currentPage === totalPages ? "disabled" : ""
                                                        }`}
                                                >
                                                    <button
                                                        className="page-link"
                                                        onClick={handleNext}
                                                        disabled={currentPage === totalPages}
                                                    >
                                                        Next
                                                    </button>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </>
    )
}