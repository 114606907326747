import { call, put } from "redux-saga/effects";
import { WebService } from "../../../../services/axios/webServices";
import { apiEndPoints } from "../../../../services/axios/endPoints";
import { ProfileCreators } from "./index";
import { getUserDataFromLocal } from "../../../../shared/utility/helper";

let userData = getUserDataFromLocal();

export function* getProfileSaga() {
  // console.log("called");
  try {
    yield put(ProfileCreators.getProfileStart());
    const response = yield WebService.get(apiEndPoints.getProfile, {
      headers: { token: userData.token },
    });
    // const response = true;

    if (response.status) {
      // console.log(response, "response");
      //   const { data } = response.data;
      yield put(ProfileCreators.getProfileSuccess(response.data.data));
    } else {
      throw response;
    }
  } catch (error) {
    if (error?.response?.status) {
      switch (error.response.status) {
        case 400:
          // yield put(
          //   ProfileCreators.getDashboardFailure({
          //     error: error?.response?.data?.message,
          //   })
          // );
          break;
        case 500:
          // yield put(
          //   ProfileCreators.getDashboardFailure({
          //     error: "Somthing went wrong try later",
          //   })
          // );
          break;
        default:
          // yield put(
          //   ProfileCreators.getDashboardFailure({
          //     error: "Unable to update. Try again later",
          //   })
          // );
      }
    } else {
      yield put(
        // ProfileCreators.getDashboardFailure({
        //   error: "No connection try again Later.",
        // })
      );
    }
  }
}

export function* getDashboardSaga() {
  try {
    yield put(ProfileCreators.getDashboardStart());
    const response = yield WebService.get(apiEndPoints.getDashboardResult, {
      headers: { token: userData.token },
    });
    // const response = true;
    console.log(response, "response");
    if (response.status) {
      // console.log(response, "response");
      //   const { data } = response.data;
      yield put(ProfileCreators.getDashboardSuccess(response.data.data));
    } else {
      throw response;
    }
  } catch (error) {
    if (error?.response?.status) {
      switch (error.response.status) {
        case 400:
          yield put(
            ProfileCreators.getDashboardFailure({
              error: error?.response?.data?.message,
            })
          );
          break;
        case 500:
          yield put(
            ProfileCreators.getDashboardFailure({
              error: "Somthing went wrong try later",
            })
          );
          break;
        default:
          yield put(
            ProfileCreators.getDashboardFailure({
              error: "Unable to update. Try again later",
            })
          );
      }
    } else {
      yield put(
        ProfileCreators.getDashboardFailure({
          error: "No connection try again Later.",
        })
      );
    }
  }
}
