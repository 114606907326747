import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useNavigate, Link } from "react-router-dom";




const useAuth = () => {
  const user = localStorage.getItem("user");
  if (user) {
    return true;
  } else {
    return false;
  }
};

const Footer = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const dispatch = useDispatch();

  const { profileData } = useSelector(
    (state) => state.profileData
  );



  const { loginmsg, userData } = useSelector((state) => state.loginData);

  return (
    <>
      <footer className="main-footer">
        <div className="pull-right hidden-xs">
          <b>Version</b> 0.0.1
        </div>
        <strong>Copyright &copy; 2024 <Link to="https://ideativemind.com">Powered by Ideativemind Private Limited</Link>.</strong> All rights
        reserved.
      </footer>
    </>
  )
}

export default Footer