import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  login: ["loginDetails"],
  onLoginStart: [],
  onLoginSuccess: ["payload"],
  onLoginFail: ["payload"],
  setUserDetails: ["payload"],
  onLogout: ["payload"],
  onLogoutSuccess: ["payload"]
  
});



export { Types, Creators };
