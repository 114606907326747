import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Creators } from "../../../auth/store";

import { getUserDataFromLocal } from "../../../../shared/utility/helper";
import Footer from "../Section/Footer";
import Sidebar from "../Section/Sidebar";
import toast, { Toaster } from "react-hot-toast";
import _ from "lodash";
import moment from 'moment';
import Loader from "../../../../Common/Loader/Loader";
import { Modal } from "react-bootstrap";
import ModalComponent from "../../../../Common/Modal/Modal";
import { sagaCatchBlockHandling } from "../../../../store/utility/sagaErrorHandling";
import { WebService } from "../../../../services/axios/webServices";
import { apiEndPoints, rootConfig } from "../../../../services/axios/endPoints";
import formatPhoneNumber from "../../../../Common/PhoneNumber/PhoneNumber";
import DistanceConverter from "../../../../Common/Kilometer/Kilometer"
import FareAmount from "../../../../Common/FareAmount/FareAmount";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { DriverCreators } from "./store";




export const InvoiceDriver = () => {

    const userData = getUserDataFromLocal();
    const { booking_id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const divRef = useRef(null);

    const [isData, setIsData] = useState({})
    const [Loading, setLoading] = useState(false)


    const { driverLoading, driverList } = useSelector((state) => state.driverData);




    useEffect(() => {
        dispatch(DriverCreators.getDriverList({ "startValue": 0, "lastValue": 10 }));
        handleDriverRideBookingDetail();
    }, []);

    const handleDriverRideBookingDetail = async () => {
        setLoading(true);

        try {
            const response = await WebService.post(
                apiEndPoints.driverRideBooking,
                JSON.stringify({ ride_id: booking_id }),
                {
                    headers: {
                        token: `${userData.token}`,
                    },
                }
            );
            // const { data } = response;
            console.log('test', response.data.data)
            if (response.status === 200) {
                setIsData(response.data.data)
                setLoading(false)
            } else {
                setLoading(false)
                throw response;
            }
        } catch (e) {
            setLoading(false)
            const error = sagaCatchBlockHandling(e);

            // console.log(error, "error000");
        }
    };

    // PDF


    const generatePdf = async () => {
        if (divRef.current) {
            // Capture the div as a canvas using html2canvas
            const canvas = await html2canvas(divRef.current);

            // Convert the canvas to an image
            const imgData = canvas.toDataURL("image/png");

            // Create a new jsPDF instance
            const pdf = new jsPDF("p", "mm", "a4");

            // Calculate image dimensions to fit the PDF page
            const imgWidth = 210; // A4 width in mm
            const imgHeight = (canvas.height * imgWidth) / canvas.width;

            // Add the image to the PDF
            pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);

            // Save the PDF
            const file = isData?.order_id + '-travel-teacher.pdf'
            pdf.save(file);
        }
    };



    return (
        <>
            <Toaster />
            <Sidebar />
            <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        {/* {_.startCase(_.toLower(isData?.role))} */}
                        Booking Details
                        {/* <small>List of passengers</small> */}
                    </h1>
                    <ol className="breadcrumb">
                        <li><Link to="/dashboard"><i className="fa fa-dashboard"></i> Home</Link></li>
                        <li><Link to="/dashboard/passenger">Passenger</Link></li>
                        <li><Link to={`dashboard/passenger/${isData?.passenger?._id}`}>{isData?.passenger_id?._id}</Link></li>

                        <li className="active">{isData?.order_id}</li>
                    </ol>
                </section>



                <section className="invoice" ref={divRef} >
                    <div className="row">

                        <div className="col-xs-9">
                            <div className="row">
                                <div className="col-xs-12">
                                    <h2 className="page-header">
                                        {/* <i className="fa fa-globe"></i>  */}
                                        #{isData?.order_id} - <span className="label label-success">{_.startCase(_.toLower(isData?.status))}</span>
                                        <small className="pull-right">Date: {moment(isData?.createdAt).format("D M, Y")}</small>
                                    </h2>
                                </div>
                            </div>
                            <div className="row invoice-info">
                                <div className="col-sm-4 invoice-col">
                                    Customer Details
                                    <address>
                                        <strong>{_.startCase(_.toLower(isData?.passenger_id?.name))}</strong><br />
                                        Phone: {formatPhoneNumber(isData?.passenger_id?.phone)}<br />
                                        Email: {isData?.passenger_id?.email}
                                    </address>
                                </div>
                                <div className="col-sm-4 invoice-col">
                                    Driver Details
                                    <address>
                                        <strong>{_.startCase(_.toLower(isData?.driver_id?.name))}</strong><br />
                                        Phone: {formatPhoneNumber(isData?.driver_id?.phone)}<br />
                                        Email: {isData?.driver_id?.email}
                                    </address>
                                </div>
                                <div className="col-sm-4 invoice-col">
                                    <b>Order #{isData?.order_id}</b><br />
                                    <br />
                                    {/* <b>Order ID:</b> 4F3S8J<br/> */}
                                    <b>Date:</b> {moment(isData?.createdAt).format("MMMM Do YYYY")}<br />
                                    <b>Time:</b> {moment(isData?.createdAt).format("h:mm:ss a")}<br />
                                    <b>Distance:</b> <DistanceConverter distance={`${isData?.distance}`} /><br />
                                    {/* <b>Account:</b> 968-34567 */}
                                </div>
                            </div>

                            <div className="row" style={{ paddingTop: '5px' }}>
                                <div className="col-xs-6">
                                    <p className="lead">Payment Methods: <strong>{isData?.payment_status}</strong> </p>

                                    <p className="text-muted well well-sm no-shadow" style={{ marginTop: '10px', backgroundColor: '#F28F3D', color: '#ffff', borderRadius: '20px' }}>
                                        <i className="fa fa-fw fa-dot-circle-o"></i> Pickup Point <br />
                                        {isData?.pickup_address} <br /><br />

                                        <i className="fa fa-fw fa-dot-circle-o"></i> Dropout Point <br />
                                        {isData?.dropoff_address}
                                    </p>
                                </div>

                            </div>

                            <div className="row no-print">
                                <div className="col-xs-12">
                                    {/* <a href="invoice-print.html" target="_blank" className="btn btn-default"><i className="fa fa-print"></i> Print</a> */}
                                    {/* <button type="button" className="btn btn-success pull-right"><i className="fa fa-credit-card"></i> Submit Payment
                                                                    </button> */}
                                    <button onClick={generatePdf} type="button" className="btn btn-primary pull-right" style={{ marginRight: '5px' }}>
                                        <i className="fa fa-download"></i> Generate PDF
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-3">
                            <div className="row">
                                <div className="col-xs-12">
                                    <p className="lead">Car Details: </p>

                                    <p className="text-muted well well-sm no-shadow" style={{ borderRadius: '20px' }}>
                                        <img src={`${rootConfig?.image_url}${isData?.vehicle_type_id?.image}`} height="70px" width="70px" style={{ borderRadius: '50%' }} /> {_.startCase(_.toLower(isData?.vehicle_type_id?.name))}
                                        <p>Person {isData?.vehicle_type_id?.person}</p>
                                    </p>
                                </div>
                                <div className="col-xs-12">
                                    <p className="lead">Amount:  <FareAmount fare_amount={isData?.fare_amount} /></p>

                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>

                                                </tr>
                                            </thead>
                                            <tr>
                                                <th style={{ width: '50%' }}>Subtotal:</th>
                                                <td><FareAmount fare_amount={isData?.fare_amount} /></td>
                                            </tr>
                                            <tr>
                                                <th>Tax (0.0%)</th>
                                                <td>0</td>
                                            </tr>
                                            {/* <tr>
                                                                                <th>Shipping:</th>
                                                                                <td>$5.80</td>
                                                                            </tr> */}
                                            <tr>
                                                <th>Total:</th>
                                                <td><FareAmount fare_amount={isData?.fare_amount} /></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </div>
            <Footer />
        </>
    )
}