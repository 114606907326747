import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { SupportTicketCreators } from "./store";
import { getUserDataFromLocal } from "../../../../shared/utility/helper";
import Sidebar from "../Section/Sidebar";
import Footer from "../Section/Footer";
import toast, { Toaster } from "react-hot-toast";
import _ from "lodash";
import moment from "moment";
import Loader from "../../../../Common/Loader/Loader";
import SwitchComponent from "../../../../Common/SwitchComponent/SwitchComponent";
import formatPhoneNumber from "../../../../Common/PhoneNumber/PhoneNumber";
import { apiEndPoints, rootConfig } from "../../../../services/axios/endPoints";
import { WebService } from "../../../../services/axios/webServices";

export const SupportTicket = () => {
  const userData = getUserDataFromLocal();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Pagination state
  const [startValue, setStartValue] = useState(0)
  const [lastValue, setLastValue] = useState(0)

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10); // Number of items per page
  const [totalPages, setTotalPages] = useState(0);


  const { supportTicketLoading, supportTicketList } = useSelector((state) => state.supportTicketData);

  // Fetch supportTicket list on component mount
  // Fetch data whenever the page changes
  useEffect(() => {
    const startValue = (currentPage - 1) * pageSize;
    const lastValue = startValue + pageSize;
    setStartValue(startValue)
    setLastValue(lastValue)
    // Dispatch API call with startValue and lastValue
    dispatch(SupportTicketCreators.getSupportTicketList({ startValue, lastValue }));

    // Set total pages based on the data from the API response (mocked for now)
    // Example: Update this logic based on your actual data structure
    setTotalPages(20); // Replace with API response total page count if available
  }, [currentPage, pageSize, dispatch]);

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };


  // console.log('abcd', supportTicketList)


  return (
    <>
      <Toaster />
      <Sidebar />
      <div className="content-wrapper">
        <section className="content-header">
          <h1>
            All SupportTicket
            <small>List of SupportTicket</small>
          </h1>
          <ol className="breadcrumb">
            <li>
              <Link to="/dashboard">
                <i className="fa fa-dashboard"></i> Home
              </Link>
            </li>
            <li className="active">All SupportTicket</li>
          </ol>
        </section>

        <section className="content">
          <div className="row">
            <div className="col-xs-12">
              <div className="box" style={{ height: "500px", overflowY: "auto" }}>
                <div className="box-header">
                  <h3 className="box-title">List of All SupportTicket</h3>
                </div>
                <div className="box-body">
                  {supportTicketLoading ? (
                    <div className="overlay">
                      <i className="fa fa-refresh fa-spin"></i>
                    </div>
                  ) : (
                    <table id="example2" className="table table-bordered table-hover">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Ticket ID</th>
                          <th>Title</th>
                          <th>Subject</th>
                          <th>Description</th>
                          <th>Image</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {supportTicketList?.map((item, index) => (
                          <tr key={item?._id || index}>
                            <td>{index + 1}</td>
                            <td>
                              <div className="user-block">
                                <span>
                                  <Link to="#"> #{item?.support_ticket_id}</Link>
                                </span>
                              </div>
                            </td>
                            <td>{item?.title || "N/A"}</td>
                            <td>{item?.subject || "N/A"}</td>
                            <td>{item?.description}</td>
                            <td>
                              <div className="user-block">
                                {item?.image?.map((itemImg, index) => {
                                  return (
                                    <img key={index}
                                      className="img-circle"
                                      src={
                                        `${rootConfig.base_url}uploads/${itemImg}`
                                      }
                                      alt="User"
                                    />
                                  )
                                })}
                              </div>
                            </td>

                            <td>
                              <div className="col-xs-12 text-center">
                                <Link
                                  to={`/dashboard/supportTicket/${item?._id}`}
                                  className="btn btn-default btn-sm"
                                >
                                  <i className="fa fa-eye"></i>&nbsp;
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
                <div className="box-footer">
                  <div className="pagination-container">
                    <nav aria-label="Page navigation">
                      <ul className="pagination justify-content-center">
                        <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                          <button
                            className="page-link"
                            onClick={handlePrevious}
                            disabled={currentPage === 1}
                          >
                            Previous
                          </button>
                        </li>
                        <li className="page-item disabled">
                          <span className="page-link">
                            Page {currentPage} of {totalPages}
                          </span>
                        </li>
                        <li
                          className={`page-item ${currentPage === totalPages ? "disabled" : ""
                            }`}
                        >
                          <button
                            className="page-link"
                            onClick={handleNext}
                            disabled={currentPage === totalPages}
                          >
                            Next
                          </button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default SupportTicket;
