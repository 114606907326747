import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Creators } from "../../../auth/store";
import { PassengerCreators } from "./store";
import { getUserDataFromLocal } from "../../../../shared/utility/helper";
import Footer from "../Section/Footer";
import Sidebar from "../Section/Sidebar";
import toast, { Toaster } from "react-hot-toast";
import _ from "lodash";
import moment from 'moment';
import Loader from "../../../../Common/Loader/Loader";
import { Modal } from "react-bootstrap";
import ModalComponent from "../../../../Common/Modal/Modal";
import { sagaCatchBlockHandling } from "../../../../store/utility/sagaErrorHandling";
import { WebService } from "../../../../services/axios/webServices";
import { apiEndPoints, rootConfig } from "../../../../services/axios/endPoints";
import formatPhoneNumber from "../../../../Common/PhoneNumber/PhoneNumber";
import DistanceConverter from "../../../../Common/Kilometer/Kilometer"
import FareAmount from "../../../../Common/FareAmount/FareAmount";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";




export const InvoicePassenger = () => {

    const userData = getUserDataFromLocal();
    const { booking_id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const divRef = useRef(null);

    const [isData, setIsData] = useState([])
    const [isRideData, setIsRideData] = useState([])


    const { passengerLoading, passengerList } = useSelector((state) => state.passengerData);

    console.log('booking_id', isRideData)


    useEffect(() => {
        dispatch(PassengerCreators.getPassengerList({ "startValue": 0, "lastValue": 10 }));
        handleCustomerRideBookingDetail();
    }, []);

    const handleCustomerRideBookingDetail = async () => {
        // setLoading(true);

        try {
            const response = await WebService.post(
                apiEndPoints.passengerRideBooking,
                JSON.stringify({ ride_id: booking_id }),
                {
                    headers: {
                        token: `${userData.token}`,
                    },
                }
            );
            // const { data } = response;
            console.log(response)
            if (response.status === 200) {
                setIsRideData(response.data.data)
            } else {
                throw response;
            }
        } catch (e) {
            const error = sagaCatchBlockHandling(e);

            // console.log(error, "error000");
        }
    };

    // PDF
        

        const generatePdf = async () => {
            if (divRef.current) {
                // Capture the div as a canvas using html2canvas
                const canvas = await html2canvas(divRef.current);

                // Convert the canvas to an image
                const imgData = canvas.toDataURL("image/png");

                // Create a new jsPDF instance
                const pdf = new jsPDF("p", "mm", "a4");

                // Calculate image dimensions to fit the PDF page
                const imgWidth = 210; // A4 width in mm
                const imgHeight = (canvas.height * imgWidth) / canvas.width;

                // Add the image to the PDF
                pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);

                // Save the PDF
                const file = isRideData?.order_id+'travel-teacher.pdf';
                pdf.save(file);
            }
        };



        return (
            <>
                <Toaster />
                <Sidebar />
                <div className="content-wrapper">
                    <section className="content-header">
                        <h1>
                            {/* {_.startCase(_.toLower(isData?.role))} */}
                            Booking Details
                            {/* <small>List of passengers</small> */}
                        </h1>
                        <ol className="breadcrumb">
                            <li><Link to="/dashboard"><i className="fa fa-dashboard"></i> Home</Link></li>
                            <li><Link to="/dashboard/passenger">Passenger</Link></li>
                            <li><Link to={`dashboard/passenger/${isRideData?.passenger?._id}`}>{isRideData?.passenger_id?._id}</Link></li>

                            <li className="active">{isRideData?.order_id}</li>
                        </ol>
                    </section>



                    <section className="invoice" ref={divRef} >
                    <div className="row">

                        <div className="col-xs-9">
                            <div className="row">
                                <div className="col-xs-12">
                                    <h2 className="page-header">
                                        {/* <i className="fa fa-globe"></i>  */}
                                        #{isRideData?.order_id} - <span className="label label-success">{_.startCase(_.toLower(isRideData?.status))}</span>
                                        <small className="pull-right">Date: {moment(isRideData?.createdAt).format("D M, Y")}</small>
                                    </h2>
                                </div>
                            </div>
                            <div className="row invoice-info">
                                <div className="col-sm-4 invoice-col">
                                    Customer Details
                                    <address>
                                        <strong>{_.startCase(_.toLower(isRideData?.passenger_id?.name))}</strong><br />
                                        Phone: {formatPhoneNumber(isRideData?.passenger_id?.phone)}<br />
                                        Email: {isRideData?.passenger_id?.email}
                                    </address>
                                </div>
                                <div className="col-sm-4 invoice-col">
                                    Driver Details
                                    <address>
                                        <strong>{_.startCase(_.toLower(isRideData?.driver_id?.name))}</strong><br />
                                        Phone: {formatPhoneNumber(isRideData?.driver_id?.phone)}<br />
                                        Email: {isRideData?.driver_id?.email}
                                    </address>
                                </div>
                                <div className="col-sm-4 invoice-col">
                                    <b>Order #{isRideData?.order_id}</b><br />
                                    <br />
                                    {/* <b>Order ID:</b> 4F3S8J<br/> */}
                                    <b>Date:</b> {moment(isRideData?.createdAt).format("MMMM Do YYYY")}<br />
                                    <b>Time:</b> {moment(isRideData?.createdAt).format("h:mm:ss a")}<br />
                                    <b>Distance:</b> <DistanceConverter distance={`${isRideData?.distance}`} /><br />
                                    {/* <b>Account:</b> 968-34567 */}
                                </div>
                            </div>

                            <div className="row" style={{ paddingTop: '5px' }}>
                                <div className="col-xs-6">
                                    <p className="lead">Payment Methods: <strong>{isRideData?.payment_status}</strong> </p>

                                    <p className="text-muted well well-sm no-shadow" style={{ marginTop: '10px', backgroundColor: '#F28F3D', color: '#ffff', borderRadius: '20px' }}>
                                        <i className="fa fa-fw fa-dot-circle-o"></i> Pickup Point <br />
                                        {isRideData?.pickup_address} <br /><br />

                                        <i className="fa fa-fw fa-dot-circle-o"></i> Dropout Point <br />
                                        {isRideData?.dropoff_address}
                                    </p>
                                </div>

                            </div>

                            <div className="row no-print">
                                <div className="col-xs-12">
                                    {/* <a href="invoice-print.html" target="_blank" className="btn btn-default"><i className="fa fa-print"></i> Print</a> */}
                                    {/* <button type="button" className="btn btn-success pull-right"><i className="fa fa-credit-card"></i> Submit Payment
                                                                    </button> */}
                                    <button onClick={generatePdf} type="button" className="btn btn-primary pull-right" style={{ marginRight: '5px' }}>
                                        <i className="fa fa-download"></i> Generate PDF
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-3">
                            <div className="row">
                                <div className="col-xs-12">
                                    <p className="lead">Car Details: </p>

                                    <p className="text-muted well well-sm no-shadow" style={{ borderRadius: '20px' }}>
                                        <img src={`${rootConfig?.image_url}${isRideData?.vehicle_type?.image}`} height="70px" width="70px" style={{ borderRadius: '50%' }} /> {_.startCase(_.toLower(isRideData?.vehicle_type?.name))}
                                        <p>Person {isRideData?.vehicle_type?.person}</p>
                                    </p>
                                </div>
                                <div className="col-xs-12">
                                    <p className="lead">Amount:  {isRideData?.fare_amount}.00 </p>

                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>

                                                </tr>
                                            </thead>
                                            <tr>
                                                <th style={{ width: '50%' }}>Subtotal:</th>
                                                <td>{isRideData?.fare_amount}.00</td>
                                            </tr>
                                            <tr>
                                                <th>Tax (0.0%)</th>
                                                <td>0</td>
                                            </tr>
                                            {/* <tr>
                                                                                <th>Shipping:</th>
                                                                                <td>$5.80</td>
                                                                            </tr> */}
                                            <tr>
                                                <th>Total:</th>
                                                <td>{isRideData?.fare_amount}.00</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                </div>
                <Footer />
            </>
        )
    }