import { createReducer } from "reduxsauce";
import { OwnerTypes } from "./action";


const INITIAL_STATE = {
    ownerLoading: false,
    ownerList: [],
    ownerCount: '',
    error: ''
}


const getOwnerListStart = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        ownerLoading: true
    }
}

const getOwnerListSuccess = (state = INITIAL_STATE, action) => {
    const { data, message } = action.payload
    
    return {
        ...state,
        ownerLoading: false,
        ownerList: data,
        ownerCount: data.length 
    }
}

const getOwnerListFailure = (state = INITIAL_STATE, action) => {
    
    return { 
        ...state,
        ownerLoading: false
     }
}


const HANDLERS = {
    [OwnerTypes.GET_OWNER_LIST_START]: getOwnerListStart,
    [OwnerTypes.GET_OWNER_LIST_SUCCESS]: getOwnerListSuccess,
    [OwnerTypes.GET_OWNER_LIST_FAILURE]: getOwnerListFailure
}

export const ownerReducer = createReducer(INITIAL_STATE, HANDLERS)