import { createReducer } from "reduxsauce";
import { Types } from "./action";

const INITIAL_STATE = {
  profileData: {},
  loading: false,
  dashboardLoading: false,
  dashboardData: {}
};

const getProfileStart = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const getProfileSuccess = (state = INITIAL_STATE, action) => {
  // console.log(action, "getProfileSuccess");

  return {
    ...state,
    loading: false,
    profileData: action.payload,
  };
};

const getProfileFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
});

/***Dashboard */
const getDashboardStart = (state = INITIAL_STATE) => ({
  ...state,
  dashboardLoading: true,
});
const getDashboardSuccess = (state = INITIAL_STATE, action) => {
  console.log('action')
  return {
    ...state,
    dashboardLoading: false,
    dashboardData: action.payload,
  };
};

const getDashboardFailure = (state = INITIAL_STATE) => ({
  ...state,
  dashboardLoading: false,
});

const HANDLERS = {
  [Types.GET_PROFILE_START]: getProfileStart,
  [Types.GET_PROFILE_SUCCESS]: getProfileSuccess,
  [Types.GET_PROFILE_FAILURE]: getProfileFailure,
  /***Dashboard */
  [Types.GET_DASHBOARD_START]: getDashboardStart,
  [Types.GET_DASHBOARD_SUCCESS]: getDashboardSuccess,
  [Types.GET_DASHBOARD_FAILOUR]: getDashboardFailure
};

export const profileReducer = createReducer(INITIAL_STATE, HANDLERS);
