import { createReducer } from "reduxsauce";
import { VehicleTypes } from "./action";


const INITIAL_STATE = {
    vehicleLoading: false,
    vehicleList: [],
    vehicleCount: '',
    error: ''
}


const getVehicleListStart = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        vehicleLoading: true
    }
}

const getVehicleListSuccess = (state = INITIAL_STATE, action) => {
    const { data, message } = action.payload
    
    return {
        ...state,
        vehicleLoading: false,
        vehicleList: data,
        vehicleCount: data.length 
    }
}

const getVehicleListFailure = (state = INITIAL_STATE, action) => {
    
    return { 
        ...state,
        vehicleLoading: false
     }
}


const HANDLERS = {
    [VehicleTypes.GET_VEHICLE_LIST_START]: getVehicleListStart,
    [VehicleTypes.GET_VEHICLE_LIST_SUCCESS]: getVehicleListSuccess,
    [VehicleTypes.GET_VEHICLE_LIST_FAILURE]: getVehicleListFailure
}

export const vehicleReducer = createReducer(INITIAL_STATE, HANDLERS)