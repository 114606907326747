import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getProfile: ['payload'],
  getProfileSuccess: ['payload'],
  getProfileStart: ['payload'],
  getProfileFailure: ['payload'],
  /***Dashboard */
  getDashboard: ['payload'],
  getDashboardSuccess: ['payload'],
  getDashboardStart: ['payload'],
  getDashboardFailour: ['payload'],
});

export { Types, Creators };
