/* eslint-disable max-len */
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import { loginReducer } from "../view/auth/store";
import { profileReducer } from "../view/Pages/Dashboard/store";

import { passengerReducer } from "../view/Pages/Dashboard/Passenger/store";
import { driverReducer } from "../view/Pages/Dashboard/Driver/store";
import { ownerReducer } from "../view/Pages/Dashboard/Owner/store";
import { bookingReducer } from "../view/Pages/Dashboard/Booking/store";
import { documentReducer } from "../view/Pages/Dashboard/Document/store";
import { supportTicketReducer } from "../view/Pages/Dashboard/SupportTicket/store";

import { supportReasonReducer } from "../view/Pages/Dashboard/SupportReason/store";
import { cancelReasonReducer } from "../view/Pages/Dashboard/CancelReason/store";

import { vehicleReducer } from "../view/Pages/Dashboard/Vehicle/store";

import { vehicleModelReducer } from "../view/Pages/Dashboard/VehicleModel/store";
import { vehicleTypesReducer } from "../view/Pages/Dashboard/VehicleTypes/store";


const rootReducer = combineReducers({
  loginData: loginReducer,
  profileData: profileReducer,
  passengerData: passengerReducer,
  driverData: driverReducer,
  ownerData: ownerReducer,
  bookingData: bookingReducer,
  documentData: documentReducer,
  supportTicketData: supportTicketReducer,
  supportReasonData: supportReasonReducer,
  cancelReasonData: cancelReasonReducer,
  vehicleData: vehicleReducer,
  vehicleModelData: vehicleModelReducer,
  vehicleTypesData: vehicleTypesReducer,

});

export default rootReducer;
