import { createReducer } from "reduxsauce";
import { PassengerTypes } from "./action";


const INITIAL_STATE = {
    passengerLoading: false,
    passengerList: [],
    passengerCount: '',
    error: ''
}


const getPassengerListStart = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        passengerLoading: true
    }
}

const getPassengerListSuccess = (state = INITIAL_STATE, action) => {
    const { data, message } = action.payload
    
    return {
        ...state,
        passengerLoading: false,
        passengerList: data,
        passengerCount: data.length 
    }
}

const getPassengerListFailure = (state = INITIAL_STATE, action) => {
    
    return { 
        ...state,
        passengerLoading: false
     }
}


const HANDLERS = {
    [PassengerTypes.GET_PASSENGER_LIST_START]: getPassengerListStart,
    [PassengerTypes.GET_PASSENGER_LIST_SUCCESS]: getPassengerListSuccess,
    [PassengerTypes.GET_PASSENGER_LIST_FAILURE]: getPassengerListFailure
}

export const passengerReducer = createReducer(INITIAL_STATE, HANDLERS)