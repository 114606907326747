import React from "react";
import ProtectedRoutes from "./ProtectedRoutes";
import { Navigate, Route, Routes } from "react-router-dom";
// import { Landing } from "../view/Pages/Landing/Landing";
import { Login } from "../view/auth/Login";
import PublicRoutes, { InnerContent, OuterContent } from "./PublicRoutes";
import { Dashboard } from "../view/Pages/Dashboard/Dashboard";

import { getUserDataFromLocal } from "../shared/utility/helper";
import { NotFound } from "../view/NotFound/NotFound";
import { Passenger } from "../view/Pages/Dashboard/Passenger/Passenger";
import { ShowPassenger } from "../view/Pages/Dashboard/Passenger/ShowPassenger";
import { Driver } from "../view/Pages/Dashboard/Driver/Driver";
import { ShowDriver } from "../view/Pages/Dashboard/Driver/ShowDriver";
import { Owner } from "../view/Pages/Dashboard/Owner/Owner";
import { ShowOwner } from "../view/Pages/Dashboard/Owner/ShowOwner";
import { ShowBooking } from "../view/Pages/Dashboard/Booking/ShowBooking";
import { Booking } from "../view/Pages/Dashboard/Booking/Booking";
import { InvoicePassenger } from "../view/Pages/Dashboard/Passenger/InvoicePassenger";
import { InvoiceDriver } from "../view/Pages/Dashboard/Driver/InvoiceDriver";
import { Document } from "../view/Pages/Dashboard/Document/Document";
import {SupportTicket} from "../view/Pages/Dashboard/SupportTicket/SupportTicket";
import SupportReason from "../view/Pages/Dashboard/SupportReason/SupportReason";
import CancelReason from "../view/Pages/Dashboard/CancelReason/CancelReason";
import Vehicle from "../view/Pages/Dashboard/Vehicle/Vehicle";
import VehicleModel from "../view/Pages/Dashboard/VehicleModel/VehicleModel";
import VehicleTypes from "../view/Pages/Dashboard/VehicleTypes/VehicleTypes";
import { Setting } from "../view/Pages/Dashboard/Setting/Setting";
import { DeleteAccount } from "../view/auth/DeleteAccount";




export const MainRoutes = () => {
  const userData = getUserDataFromLocal();

  return (
    <Routes>
      <Route path="/" element={<ProtectedRoutes />}>
        <Route path="/" element={<InnerContent />}>
          <Route index element={<Dashboard />} />
          {/* <Route path="/" element={<Navigate replace to="mainpage" />} /> */}
          <Route path="dashboard" element={<Dashboard />} />
          
          
          <Route path="dashboard/passenger" element={<Passenger />} />
          <Route path="dashboard/passenger/:passenger_id" element={<ShowPassenger />} />
          <Route path="dashboard/booking/passenger/details/:booking_id" element={<InvoicePassenger />} />

          <Route path="dashboard/driver" element={<Driver />} />
          <Route path="dashboard/driver/:driver_id" element={<ShowDriver />} />
          <Route path="dashboard/booking/driver/details/:booking_id" element={<InvoiceDriver />} />


          <Route path="dashboard/owner" element={<Owner />} />
          <Route path="dashboard/owner/:owner_id" element={<ShowOwner />} />
          
          <Route path="dashboard/booking" element={<Booking />} />
          <Route path="dashboard/booking/:booking_id" element={<ShowBooking />} />

          <Route path="dashboard/documents" element={<Document />} />


          <Route path="dashboard/support/ticket/list" element={<SupportTicket />} />

          <Route path="dashboard/support/reason/list" element={<SupportReason />} />

          <Route path="dashboard/cancel/reason/list" element={<CancelReason />} />

          <Route path="dashboard/vehicle/list" element={<Vehicle />} />
          
          <Route path="dashboard/vehicle/model/list" element={<VehicleModel />} />

          <Route path="dashboard/vehicle/type/list" element={<VehicleTypes />} />

          <Route path="dashboard/setting" element={<Setting />} />
          
          
        </Route>
      </Route>
      {/* <Route path="/" element={<PublicRoutes />}> */}
      <Route path="/" element={<OuterContent />}>
        {/* <Route path="/" element={<Navigate replace to="dashboard" />} /> */}
        {/* <Route path="home" element={<Landing />} /> */}
        <Route path="login" element={<Login />} />
        <Route path="delete/account" element={<DeleteAccount />} />

        
        
      </Route>
      <Route path="/404" element={<NotFound />} />
      <Route path="*" element={<Navigate to="/404" />}></Route>
      {/* </Route> */}
    </Routes>
  );
};
