import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Creators } from "../../../auth/store";
import { BookingCreators } from "./store";
import { getUserDataFromLocal } from "../../../../shared/utility/helper";
import Footer from "../Section/Footer";
import Sidebar from "../Section/Sidebar";
import { Toaster } from "react-hot-toast";
import _ from "lodash";
import moment from 'moment';
import Loader from "../../../../Common/Loader/Loader";
import Modal from "../../../../Common/Modal/Modal"
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import ModalComponent from "../../../../Common/Modal/Modal";
import { rootConfig } from "../../../../services/axios/endPoints";




export const Booking = () => {

    const userData = getUserDataFromLocal();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Pagination state
    const [startValue, setStartValue] = useState(0)
    const [lastValue, setLastValue] = useState(0)

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(10); // Number of items per page
    const [totalPages, setTotalPages] = useState(0);


    const [isModalOpen, setModalOpen] = useState(false);
    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    const [isBookingObj, setIsBookingObj] = useState({})

    const { bookingLoading, bookingList } = useSelector((state) => state.bookingData);

    // console.log('bookingData', bookingList)


    // useEffect(() => {
    //     dispatch(BookingCreators.getBookingList({ "startValue": 0, "lastValue": 10 }));

    // }, []);

    useEffect(() => {
        const startValue = (currentPage - 1) * pageSize;
        const lastValue = startValue + pageSize;
        setStartValue(startValue)
        setLastValue(lastValue)
        // Dispatch API call with startValue and lastValue
        dispatch(BookingCreators.getBookingList({ startValue, lastValue }));

        // Set total pages based on the data from the API response (mocked for now)
        // Example: Update this logic based on your actual data structure
        setTotalPages(20); // Replace with API response total page count if available
    }, [currentPage, pageSize, dispatch]);

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage((prev) => prev - 1);
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage((prev) => prev + 1);
        }
    };


    const handleShowItem = async (item) => {

        openModal(item)
    }


    return (
        <>
            <Toaster />

            <Sidebar />
            <div className="content-wrapper">
                <div>

                    <Modal className="modal" isOpen={isModalOpen} onClose={closeModal}>
                        <section className="content-header">
                            <h1>
                                All Bookings
                                <small>List of bookings</small>
                            </h1>
                            <ol className="breadcrumb">
                                <li><Link to="/dashboard"><i className="fa fa-dashboard"></i> Home</Link></li>

                                <li className="active">All Bookings</li>
                            </ol>
                        </section>
                    </Modal>
                </div>
                <section className="content-header">
                    <h1>
                        All Bookings
                        <small>List of bookings</small>
                    </h1>
                    <ol className="breadcrumb">
                        <li><Link to="/dashboard"><i className="fa fa-dashboard"></i> Home</Link></li>

                        <li className="active">All Bookings</li>
                    </ol>
                </section>


                <section className="content">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="box" style={{ height: '500px', overflowY: 'auto' }}>
                                <div className="box-header">
                                    <h3 className="box-title">List of All Bookings</h3>
                                </div>

                                <div className="box-body">
                                    <table id="example2" className="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Order Id</th>
                                                <th>Customer Name</th>
                                                <th>Booking Date</th>
                                                <th>Payment Status</th>
                                                <th>Booking Status</th>
                                                <th>Total</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                bookingLoading ? (
                                                    <div className="overlay">
                                                        <i className="fa fa-refresh fa-spin"></i>
                                                    </div>
                                                ) : bookingList?.map((item, index) => {
                                                    return (
                                                        <tr key={index + 1}>
                                                            <td>{index + 1}</td>
                                                            <td>{item?.order_id}</td>
                                                            <td>
                                                                <div className="user-block">
                                                                    <span className="username"><Link to="#">{_.startCase(_.toLower(item?.name))}</Link></span>
                                                                    {/* <span className="description">Email - {item?.email}</span> */}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {moment(item?.createdAt).format('MMMM Do YYYY, h:mm:ss a')}
                                                            </td>
                                                            <td>
                                                                {item.payment_status == null ? 'UnPaid' : item.payment_status}
                                                            </td>
                                                            <td>
                                                                {item.status}
                                                            </td>
                                                            <td>{item?.fare_amount}</td>
                                                            <td>

                                                                <Link to={`/dashboard/booking/${item._id}`} type="button" className="btn btn-default btn-sm"
                                                                // onClick={() => handleShowItem(item)} 
                                                                >
                                                                    <i className="fa fa-eye"></i>&nbsp;
                                                                    Show
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>

                                <div className="box-footer">
                                    <div className="pagination-container">
                                        <nav aria-label="Page navigation">
                                            <ul className="pagination justify-content-center">
                                                <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                                                    <button
                                                        className="page-link"
                                                        onClick={handlePrevious}
                                                        disabled={currentPage === 1}
                                                    >
                                                        Previous
                                                    </button>
                                                </li>
                                                <li className="page-item disabled">
                                                    <span className="page-link">
                                                        Page {currentPage} of {totalPages}
                                                    </span>
                                                </li>
                                                <li
                                                    className={`page-item ${currentPage === totalPages ? "disabled" : ""
                                                        }`}
                                                >
                                                    <button
                                                        className="page-link"
                                                        onClick={handleNext}
                                                        disabled={currentPage === totalPages}
                                                    >
                                                        Next
                                                    </button>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </>
    )
}