import React from 'react';

const FareAmount = ({ fare_amount }) => {
  // Extract and parse the decimal value
  const amount = parseFloat(fare_amount?.$numberDecimal);

  // Handle invalid or missing fare_amount
  if (isNaN(amount)) {
    return <div>Invalid fare amount</div>;
  }

  return (
    <>
      {`₹${amount.toFixed(2)}`}
    </>
  );
};

export default FareAmount;
