import React from 'react';
import styled, { css } from 'styled-components';

const Modal = ({ isOpen, onClose, title, children, footer, size }) => {
  if (!isOpen) return null;

  return (
    <Overlay>
      <ModalContainer size={size}>
        <Header>
          <Title>{title}</Title>
          <CloseButton onClick={onClose}>&times;</CloseButton>
        </Header>
        <Body>{children}</Body>
        {footer && <Footer>{footer}</Footer>}
      </ModalContainer>
    </Overlay>
  );
};

// Styled components
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
`;

const ModalContainer = styled.div`
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  z-index: 1051;
  width: 90%;
  max-width: ${({ size }) =>
    size === 'large'
      ? '900px'
      : size === 'small'
      ? '400px'
      : '600px'}; /* Default is medium size */
`;

const Header = styled.div`
  background: #f8f9fa;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
`;

const Title = styled.h5`
  margin: 0;
  font-size: 18px;
  font-weight: 600;
`;

const CloseButton = styled.button`
  background: transparent;
  border: none;
  font-size: 20px;
  color: #000;
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.2);
  }
`;

const Body = styled.div`
  padding: 16px;
  font-size: 14px;
  color: #212529;
  overflow-y: auto;
  max-height: 60vh; /* Ensure the modal remains scrollable */
`;

const Footer = styled.div`
  padding: 16px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  background: #f8f9fa;
  border-top: 1px solid #dee2e6;
`;

export default Modal;
export const ErrorP = styled.span`
  color: red;
  bottom: 1rem;
  left: 1rem;
  font-size: 12px;
`;