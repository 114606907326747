import { apiRootConfig } from "../../config/env.config";

/* eslint-disable max-len */
export const rootConfig = {
  apiRoot: apiRootConfig.apiRoot,
  base_url: apiRootConfig.base_url,
  image_url: apiRootConfig.image_url,
};

export const apiEndPoints = {

  DeleteAccount: `${rootConfig.apiRoot}admin/delete/account`,

  login: `${rootConfig.apiRoot}admin/signin`,
  userLogout: `${rootConfig.apiRoot}user/logout`,
  getProfile: `${rootConfig.apiRoot}admin/profile/preview`,

  getDashboardResult : `${rootConfig.apiRoot}admin/dashboardCount`,

  /*** Passenger List */
  passengerList: `${rootConfig.apiRoot}admin/customer/list`,
  passengerPreview: `${rootConfig.apiRoot}admin/customer/preview`,
  passengerStatus: `${rootConfig.apiRoot}admin/customer/status`,
  passengerSuspend: `${rootConfig.apiRoot}admin/customer/suspend`,
  passengerRideList: `${rootConfig.apiRoot}admin/customer/booking/list`,
  passengerRideBooking: `${rootConfig.apiRoot}admin/customer/ride/invoice`,
  /*** Passenger List */
  /** Driver List */
  driverList: `${rootConfig.apiRoot}admin/driver/list`,
  driverPreview: `${rootConfig.apiRoot}admin/driver/preview`,
  driverStatus: `${rootConfig.apiRoot}admin/driver/status`,
  driverSuspend: `${rootConfig.apiRoot}admin/driver/suspend`,
  driverRideList: `${rootConfig.apiRoot}admin/driver/booking/list`,
  driverRideBooking: `${rootConfig.apiRoot}admin/driver/ride/invoice`,
  driverDocumentStatus: `${rootConfig.apiRoot}admin/driver/document/approve`,
  driverVehicleStatus: `${rootConfig.apiRoot}admin/driver/vehicle/approve`,
  /** Driver List */

  /*** Document  */
  
  documentCreate: `${rootConfig.apiRoot}admin/document/create`,
  documentList: `${rootConfig.apiRoot}admin/document/list`,
  documentStatus: `${rootConfig.apiRoot}admin/document/status`,
  /*** Document  */

  supportticketList: `${rootConfig.apiRoot}admin/support/ticket/list`,


  supportreasonList: `${rootConfig.apiRoot}admin/support_reason/list`,
  supportreasonStatus: `${rootConfig.apiRoot}admin/support_reason/status`,
  supportreasonCreate: `${rootConfig.apiRoot}admin/support_reason/create`,

  

  cancelreasonList: `${rootConfig.apiRoot}admin/cancel_reason/list`,
  cancelreasonStatus: `${rootConfig.apiRoot}admin/cancel_reason/status`,
  cancelreasonCreate: `${rootConfig.apiRoot}admin/cancel_reason/create`,

  


  vehicleList: `${rootConfig.apiRoot}admin/vehicle/brand/list`,
  vehicleStatus: `${rootConfig.apiRoot}admin/vehicle/brand/status`,
  vehicleCreate: `${rootConfig.apiRoot}admin/vehicle/brand/create`,

  vehicleModelList: `${rootConfig.apiRoot}admin/vehicle/model/list`,
  vehicleModelStatus: `${rootConfig.apiRoot}admin/vehicle/model/status`,
  vehicleModelCreate: `${rootConfig.apiRoot}admin/vehicle/model/create`,
  

  vehicleTypeList: `${rootConfig.apiRoot}admin/vehicle/type/list`,
  vehicleTypeStatus: `${rootConfig.apiRoot}admin/vehicle/type/status`,
  vehicleTypeCreate: `${rootConfig.apiRoot}admin/vehicle/type/create`,
  
  
  /***Owner List */
  ownerList: `${rootConfig.apiRoot}admin/owner/list`,
  ownerPreview: `${rootConfig.apiRoot}admin/owner/preview`,
  ownerStatus: `${rootConfig.apiRoot}admin/owner/status`,
  ownerSuspend: `${rootConfig.apiRoot}admin/owner/suspend`,
  /***Owner List */
  /*** Booking List */
  bookingList: `${rootConfig.apiRoot}admin/booking/list`,
  bookingInvoice: `${rootConfig.apiRoot}admin/booking/invoice`,
  /*** Booking List */

  

  // Check Current City Api
  currentCityApi: apiRootConfig.google_map_reverse_link,

  


};

export const firebase = {
  apiKey: apiRootConfig.firebase_api_key,
  authDomain: apiRootConfig.firebase_auth_domain,
  projectId: apiRootConfig.firebase_project_id,
  storageBucket: apiRootConfig.firebase_storage_bucket,
  messagingSenderId: apiRootConfig.firebase_message_sender_id,
  appId: apiRootConfig.firebase_app_id,
  measurementId: apiRootConfig.firebase_measurement_id,

  // 
  //Vapid Key
  vapidKey: apiRootConfig.firebase_vapid_key

}
