import { createReducer } from "reduxsauce";
import { CancelReasonTypes } from "./action";


const INITIAL_STATE = {
    cancelReasonLoading: false,
    cancelReasonList: [],
    cancelReasonCount: '',
    error: ''
}


const getCancelReasonListStart = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        cancelReasonLoading: true
    }
}

const getCancelReasonListSuccess = (state = INITIAL_STATE, action) => {
    const { data, message } = action.payload
    
    return {
        ...state,
        cancelReasonLoading: false,
        cancelReasonList: data,
        cancelReasonCount: data.length 
    }
}

const getCancelReasonListFailure = (state = INITIAL_STATE, action) => {
    
    return { 
        ...state,
        cancelReasonLoading: false
     }
}


const HANDLERS = {
    [CancelReasonTypes.GET_CANCEL_REASON_LIST_START]: getCancelReasonListStart,
    [CancelReasonTypes.GET_CANCEL_REASON_LIST_SUCCESS]: getCancelReasonListSuccess,
    [CancelReasonTypes.GET_CANCEL_REASON_LIST_FAILURE]: getCancelReasonListFailure
}

export const cancelReasonReducer = createReducer(INITIAL_STATE, HANDLERS)