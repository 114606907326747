import { createReducer } from "reduxsauce";
import { SupportTicketTypes } from "./action";


const INITIAL_STATE = {
    supportTicketLoading: false,
    supportTicketList: [],
    supportTicketCount: '',
    error: ''
}


const getSupportTicketListStart = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        supportTicketLoading: true
    }
}

const getSupportTicketListSuccess = (state = INITIAL_STATE, action) => {
    const { data, message } = action.payload
    
    return {
        ...state,
        supportTicketLoading: false,
        supportTicketList: data,
        supportTicketCount: data.length 
    }
}

const getSupportTicketListFailure = (state = INITIAL_STATE, action) => {
    
    return { 
        ...state,
        supportTicketLoading: false
     }
}


const HANDLERS = {
    [SupportTicketTypes.GET_SUPPORT_TICKET_LIST_START]: getSupportTicketListStart,
    [SupportTicketTypes.GET_SUPPORT_TICKET_LIST_SUCCESS]: getSupportTicketListSuccess,
    [SupportTicketTypes.GET_SUPPORT_TICKET_LIST_FAILURE]: getSupportTicketListFailure
}

export const supportTicketReducer = createReducer(INITIAL_STATE, HANDLERS)