import { createReducer } from "reduxsauce";
import { VehicleTypesTypes } from "./action";


const INITIAL_STATE = {
    vehicleTypesLoading: false,
    vehicleTypesList: [],
    vehicleTypesCount: '',
    error: ''
}


const getVehicleTypesListStart = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        vehicleTypesLoading: true
    }
}

const getVehicleTypesListSuccess = (state = INITIAL_STATE, action) => {
    const { data, message } = action.payload
    
    return {
        ...state,
        vehicleTypesLoading: false,
        vehicleTypesList: data,
        vehicleTypesCount: data.length 
    }
}

const getVehicleTypesListFailure = (state = INITIAL_STATE, action) => {
    
    return { 
        ...state,
        vehicleTypesLoading: false
     }
}


const HANDLERS = {
    [VehicleTypesTypes.GET_VEHICLE_TYPES_LIST_START]: getVehicleTypesListStart,
    [VehicleTypesTypes.GET_VEHICLE_TYPES_LIST_SUCCESS]: getVehicleTypesListSuccess,
    [VehicleTypesTypes.GET_VEHICLE_TYPES_LIST_FAILURE]: getVehicleTypesListFailure
}

export const vehicleTypesReducer = createReducer(INITIAL_STATE, HANDLERS)