import { ProfileCreators, ProfileTypes } from "../view/Pages/Dashboard/store";
import {
  Types as LoginTypes,
  Creators as LoginCreators,
} from "../view/auth/store";

import { PassengerCreators, PassengerTypes } from "../view/Pages/Dashboard/Passenger/store";
import { DriverCreators, DriverTypes } from "../view/Pages/Dashboard/Driver/store";
import { OwnerCreators, OwnerTypes } from "../view/Pages/Dashboard/Owner/store";
import { BookingCreators, BookingTypes } from "../view/Pages/Dashboard/Booking/store";
import { DocumentCreators, DocumentTypes } from "../view/Pages/Dashboard/Document/store";
import { SupportTicketCreators, SupportTicketTypes } from "../view/Pages/Dashboard/SupportTicket/store";
import { SupportReasonCreators, SupportReasonTypes } from "../view/Pages/Dashboard/SupportReason/store";
import { CancelReasonCreators, CancelReasonTypes } from "../view/Pages/Dashboard/CancelReason/store";
import { VehicleCreators, VehicleTypes } from "../view/Pages/Dashboard/Vehicle/store";
import { VehicleModelCreators, VehicleModelTypes } from "../view/Pages/Dashboard/VehicleModel/store";
import { VehicleTypesCreators, VehicleTypesTypes } from "../view/Pages/Dashboard/VehicleTypes/store";



const Types = {
  ...LoginTypes,
  ...ProfileTypes,
  /** Passenger */
  ...PassengerTypes,
  /**Driver */
  ...DriverTypes,
  ...OwnerTypes,
  ...BookingTypes,
  ...DocumentTypes,
  ...SupportTicketTypes,
  ...SupportReasonTypes,
  ...CancelReasonTypes,
  ...VehicleTypes,
  ...VehicleModelTypes,
  ...VehicleTypesTypes,
};

const Creators = {
  ...LoginCreators,
  ...ProfileCreators,
  /** Passenger */
  ...PassengerCreators,
  /**Driver */
  ...DriverCreators,
  ...OwnerCreators,
  ...BookingCreators,
  ...DocumentCreators,
  ...SupportTicketCreators,
  ...SupportReasonCreators,
  ...CancelReasonCreators,
  ...VehicleCreators,
  ...VehicleModelCreators,
  ...VehicleTypesCreators,
};

export { Types, Creators };
